<template>
	<div v-if="loaded">
		<h3>{{ company_name }} - {{ office_name }}</h3>
		<h2>
			Questionario Mobility Label
		</h2>
		<hr />
		<div>
			<b-form-group :label="labels.box1.label" label-size="lg">
				<b-form-checkbox v-model="form.box1.accessible" value="1" unchecked-value="0">{{ labels.box1.accessible
					}}<span class="description">{{ descriptions.box1.accessible }}</span>
				</b-form-checkbox>
				<b-form-checkbox v-model="form.box1.theft_prevention" value="1" unchecked-value="0">{{
					labels.box1.theft_prevention }}<span class="description">{{ descriptions.box1.theft_prevention
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box1.arrival_bike" value="1" unchecked-value="0">{{
					labels.box1.arrival_bike }}<span class="description">{{ descriptions.box1.arrival_bike
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box1.internal_bikes" value="1" unchecked-value="0">{{
					labels.box1.internal_bikes }}<span class="description">{{ descriptions.box1.internal_bikes
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box1.pool_bikes" value="1" unchecked-value="0">{{ labels.box1.pool_bikes
					}}<span class="description">{{ descriptions.box1.pool_bikes }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box1.cycling_business_trips" value="1" unchecked-value="0">{{
					labels.box1.cycling_business_trips }}<span class="description">{{
						descriptions.box1.cycling_business_trips }}</span></b-form-checkbox>
				<!--<b-form-checkbox v-model="form.box1.bike_sharing" value="1" unchecked-value="0"
                >{{ labels.box1.bike_sharing }}<span class="description">{{ descriptions.box1.bike_sharing }}</span></b-form-checkbox
            > -->
				<b-form-checkbox v-model="form.box1.internal_cycling" value="1" unchecked-value="0">{{
					labels.box1.internal_cycling }}<span class="description">{{ descriptions.box1.internal_cycling
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box1.cycling_infrast" value="1" unchecked-value="0">{{
					labels.box1.cycling_infrast }}<span class="description">{{ descriptions.box1.cycling_infrast
						}}</span></b-form-checkbox>
			</b-form-group>

			<b-form-group :label="labels.box2.label" label-size="lg">
				<b-form-checkbox v-model="form.box2.azessible" value="1" unchecked-value="0">{{ labels.box2.azessible
					}}<span class="description">{{ descriptions.box2.azessible }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box2.infrastructure_walk" value="1" unchecked-value="0">{{
					labels.box2.infrastructure_walk }}<span class="description">{{ descriptions.box2.infrastructure_walk
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box2.arrival_walk" value="1" unchecked-value="0">{{
					labels.box2.arrival_walk }}<span class="description">{{ descriptions.box2.arrival_walk
						}}</span></b-form-checkbox>
			</b-form-group>

			<b-form-group :label="labels.box3.label" label-size="lg">
				<b-form-checkbox v-model="form.box3.bus_accessible" value="1" unchecked-value="0">{{
					labels.box3.bus_accessible }}<span class="description">{{ descriptions.box3.bus_accessible
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box3.bus_info" value="1" unchecked-value="0">{{ labels.box3.bus_info
					}}<span class="description">{{ descriptions.box3.bus_info }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box3.co_financing" value="1" unchecked-value="0">{{
					labels.box3.co_financing }}<span class="description">{{ descriptions.box3.co_financing
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box3.bus_facilities" value="1" unchecked-value="0">{{
					labels.box3.bus_facilities }}<span class="description">{{ descriptions.box3.bus_facilities
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box3.lobby" value="1" unchecked-value="0">{{ labels.box3.lobby }}<span
						class="description">{{ descriptions.box3.lobby }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box3.bus_infrastructure" value="1" unchecked-value="0">{{
					labels.box3.bus_infrastructure }}<span class="description">{{ descriptions.box3.bus_infrastructure
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box3.shuttle_bus" value="1" unchecked-value="0">{{
					labels.box3.shuttle_bus }}<span class="description">{{ descriptions.box3.shuttle_bus
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box3.vanpooling" value="1" unchecked-value="0">{{ labels.box3.vanpooling
					}}<span class="description">{{ descriptions.box3.vanpooling }}</span></b-form-checkbox>
			</b-form-group>

			<b-form-group :label="labels.box4.label" label-size="lg">
				<b-form-checkbox v-model="form.box4.matching_service" value="1" unchecked-value="0">{{
					labels.box4.matching_service }}<span class="description">{{ descriptions.box4.matching_service
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box4.parking_carpooling" value="1" unchecked-value="0">{{
					labels.box4.parking_carpooling }}<span class="description">{{ descriptions.box4.parking_carpooling
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box4.carpooling_incentives" value="1" unchecked-value="0">{{
					labels.box4.carpooling_incentives }}<span class="description">{{
						descriptions.box4.carpooling_incentives }}</span></b-form-checkbox>
			</b-form-group>

			<b-form-group :label="labels.box5.label" label-size="lg">
				<b-form-checkbox v-model="form.box5.carsharing" value="1" unchecked-value="0">{{ labels.box5.carsharing
					}}<span class="description">{{ descriptions.box5.carsharing }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box5.carsharing_convenzioni" value="1" unchecked-value="0">{{
					labels.box5.carsharing_convenzioni }}<span class="description">{{
						descriptions.box5.carsharing_convenzioni }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box5.bike_sharing" value="1" unchecked-value="0">{{
					labels.box5.bike_sharing }}<span class="description">{{ descriptions.box5.bike_sharing
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box5.bike_sharing_convenzioni" value="1" unchecked-value="0">{{
					labels.box5.bike_sharing_convenzioni }}<span class="description">{{
						descriptions.box5.bike_sharing_convenzioni }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box5.scooter_sharing" value="1" unchecked-value="0">{{
					labels.box5.scooter_sharing }}<span class="description">{{ descriptions.box5.scooter_sharing
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box5.scooter_sharing_convenzioni" value="1" unchecked-value="0">{{
					labels.box5.scooter_sharing_convenzioni }}<span class="description">{{
						descriptions.box5.scooter_sharing_convenzioni }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box5.monopattini" value="1" unchecked-value="0">{{
					labels.box5.monopattini }}<span class="description">{{ descriptions.box5.monopattini
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box5.monopattini_convenzioni" value="1" unchecked-value="0">{{
					labels.box5.monopattini_convenzioni }}<span class="description">{{
						descriptions.box5.monopattini_convenzioni }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box5.incentives_sharing" value="1" unchecked-value="0">{{
					labels.box5.incentives_sharing }}<span class="description">{{ descriptions.box5.incentives_sharing
						}}</span></b-form-checkbox>
			</b-form-group>

			<b-form-group :label="labels.box6.label" label-size="lg">
				<b-form-checkbox v-model="form.box6.accessibility" value="1" unchecked-value="0">{{
					labels.box6.accessibility }}<span class="description">{{ descriptions.box6.accessibility
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.business_trips" value="1" unchecked-value="0">{{
					labels.box6.business_trips }}<span class="description">{{ descriptions.box6.business_trips
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.efficient_use_work" value="1" unchecked-value="0">{{
					labels.box6.efficient_use_work }}<span class="description">{{ descriptions.box6.efficient_use_work
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.sensible_car_use" value="1" unchecked-value="0">{{
					labels.box6.sensible_car_use }}<span class="description">{{ descriptions.box6.sensible_car_use
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.park_ride" value="1" unchecked-value="0">{{ labels.box6.park_ride
					}}<span class="description">{{ descriptions.box6.park_ride }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.parking_fee" value="1" unchecked-value="0">{{
					labels.box6.parking_fee }}<span class="description">{{ descriptions.box6.parking_fee
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.parking_management" value="1" unchecked-value="0">{{
					labels.box6.parking_management }}<span class="description">{{ descriptions.box6.parking_management
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.car_free" value="1" unchecked-value="0">{{ labels.box6.car_free
					}}<span class="description">{{ descriptions.box6.car_free }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.access_management" value="1" unchecked-value="0">{{
					labels.box6.access_management }}<span class="description">{{ descriptions.box6.access_management
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.vhl_restrictions" value="1" unchecked-value="0">{{
					labels.box6.vhl_restrictions }}<span class="description">{{ descriptions.box6.vhl_restrictions
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.clean_vhls" value="1" unchecked-value="0">{{ labels.box6.clean_vhls
					}}<span class="description">{{ descriptions.box6.clean_vhls }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.alternative_fuels" value="1" unchecked-value="0">{{
					labels.box6.alternative_fuels }}<span class="description">{{ descriptions.box6.alternative_fuels
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.eco_driving" value="1" unchecked-value="0">{{
					labels.box6.eco_driving }}<span class="description">{{ descriptions.box6.eco_driving
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.its" value="1" unchecked-value="0">{{ labels.box6.its }}<span
						class="description">{{ descriptions.box6.its }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box6.route_planning" value="1" unchecked-value="0">{{
					labels.box6.route_planning }}<span class="description">{{ descriptions.box6.route_planning
						}}</span></b-form-checkbox>
			</b-form-group>

			<b-form-group :label="labels.box7.label" label-size="lg">
				<b-form-checkbox v-model="form.box7.accessibility_signs" value="1" unchecked-value="0">{{
					labels.box7.accessibility_signs }}<span class="description">{{ descriptions.box7.accessibility_signs
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box7.infrastructure_mobility" value="1" unchecked-value="0">{{
					labels.box7.infrastructure_mobility }}<span class="description">{{
						descriptions.box7.infrastructure_mobility }}</span></b-form-checkbox>
			</b-form-group>

			<b-form-group :label="labels.box8.label" label-size="lg">
				<b-form-checkbox v-model="form.box8.financial_incentives" value="1" unchecked-value="0">{{
					labels.box8.financial_incentives }}<span class="description">{{
						descriptions.box8.financial_incentives }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box8.taxi_service" value="1" unchecked-value="0">{{
					labels.box8.taxi_service }}<span class="description">{{ descriptions.box8.taxi_service
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box8.trip_advice" value="1" unchecked-value="0">{{
					labels.box8.trip_advice }}<span class="description">{{ descriptions.box8.trip_advice
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box8.flexibility" value="1" unchecked-value="0">{{
					labels.box8.flexibility }}<span class="description">{{ descriptions.box8.flexibility
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box8.teleworking" value="1" unchecked-value="0">{{
					labels.box8.teleworking }}<span class="description">{{ descriptions.box8.teleworking
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box8.mobility_office" value="1" unchecked-value="0">{{
					labels.box8.mobility_office }}<span class="description">{{ descriptions.box8.mobility_office
						}}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box8.land_use" value="1" unchecked-value="0">{{ labels.box8.land_use
					}}<span class="description">{{ descriptions.box8.land_use }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box8.e_shopping" value="1" unchecked-value="0">{{ labels.box8.e_shopping
					}}<span class="description">{{ descriptions.box8.e_shopping }}</span></b-form-checkbox>
				<b-form-checkbox v-model="form.box8.general_services" value="1" unchecked-value="0">{{
					labels.box8.general_services }}<span class="description">{{ descriptions.box8.general_services
						}}</span></b-form-checkbox>
			</b-form-group>
		</div>

		<b-button variant="primary" @click="submit()">Salva</b-button>
	</div>
</template>

<script>
import MobilityLabelService from "../mobility_label.service";
import Util from "@/mixing/util";

//Qui inseriamo tutte le label dei campi dei form
//@IOANNA: Sono da tradurre in Italiano. Sul sito MomaBIZ trovi già le frasi tradotte.
const labels = {
	company_name: "Nome Azienda",
	survey_date: "Data a cui si riferisce il questionario",
	box1: {
		label: "BOX 1: Ciclabilità", //@IOANNA: tutto da tradurre
		accessible: "1.1 L'azienda è accessibile in bici",
		theft_prevention: "1.2 Prevenzione dei furti di bici",
		arrival_bike: "1.3 Facilities per chi si sposta in bici",
		internal_bikes: "1.4 Biciclette aziendali - spostamenti all'interno dell'azienda e per lavoro",
		pool_bikes: "1.5 Biciclette aziendali - spostamenti per casa-lavoro e non",
		cycling_business_trips: "1.6 Ciclabilità per gli spostamenti per lavoro",
		//bike_sharing: "1.7 Bike sharing",
		internal_cycling: "1.7 Strutture e servizi per la ciclabilità presso la sede aziendale (piste ciclabili, rampe, kit manutenzione biciclette ecc.)",
		cycling_infrast: "1.8 Collaborazione con gli enti per il miglioramento della rete ciclabile",
	},

	box2: {
		label: "BOX 2: Pedonalità",
		azessible: "2.1 L'azienda è accessibile a piedi",
		infrastructure_walk: "2.2 Infrastrutture sicure per pedoni",
		arrival_walk: "2.3 Facilities per chi si sposta a piedi", //L'ultima riga è bene che finisca con virgola
	},

	box3: {
		label: "BOX 3: Trasporto pubblico",
		bus_accessible: "3.1 L'azienda è accessibile con il trasporto pubblico",
		bus_info: "3.2 Informazioni sul trasporto pubblico",
		co_financing: "3.3 Cofinanziamento dei biglietti di trasporto pubblico",
		bus_facilities: "3.4 Infrastrutture/servizi di trasporto pubblico nelle vicinanze",
		lobby: "3.5 Collaborazione con gli enti per il miglioramento del trasporto pubblico",
		bus_infrastructure: "3.6 Infrastrutture per i pedoni all'interno della sede",
		shuttle_bus: "3.7 Navetta aziendale",
		vanpooling: "3.8 Vanpooling", //L'ultima riga è bene che finisca con virgola
	},

	box4: {
		label: "BOX 4: Carpooling",
		matching_service: "4.1 Formazione di equipaggi",
		parking_carpooling: "4.2 Posteggi per chi fa carpooling",
		carpooling_incentives: "4.3 Incentivi al carpooling", //L'ultima riga è bene che finisca con virgola
	},

	box5: {
		label: "BOX 5: Sharing Mobility",
		carsharing: "5.1 L'azienda è servita dal car sharing",
		carsharing_convenzioni: "5.2 Facilitazioni car sharing per i dipendenti",
		bike_sharing: "5.3 L'azienda è servita dal bike sharing",
		bike_sharing_convenzioni: "5.4 Facilitazioni bike sharing per i dipendenti",
		scooter_sharing: "5.5 L'azienda è servita dallo scooter/moto sharing",
		scooter_sharing_convenzioni: "5.6 Facilitazioni scooter/moto sharing per i dipendenti",
		monopattini: "5.7 L'azienda è servita da uno o più servizi di monopattini elettrici",
		monopattini_convenzioni: "5.8 Facilitazioni per l'uso dei monopattini elettrici per dipendenti",
		incentives_sharing: "5.9 Incentivi per la sharing mobility",
	},

	box6: {
		label: "BOX 6: Auto private/aziendali",
		accessibility: "6.1 Accessibilità in auto",
		business_trips: "6.2 Riduzione delle trasferte di lavoro",
		efficient_use_work: "6.3 Alternative all'uso individuale dell'auto per gli spostamenti per lavoro",
		sensible_car_use: "6.4 Flotta aziendale disponibile per i dipendenti",
		park_ride: "6.5 Park & ride (Posteggi di Interscambio)",
		parking_fee: "6.6 Sosta a pagamento",
		parking_management: "6.7 Gestione della sosta presso l'azienda",
		car_free: "6.8 Car free areas",
		access_management: "6.9 Gestione dell'accesso alla sede",
		vhl_restrictions: "6.10 Restrizioni per i veicoli più inquinanti",
		clean_vhls: "6.11 Flotta aziendale a basso impatto ambientale",
		alternative_fuels: "6.12 Promozione di combustibili alternativi",
		eco_driving: "6.13 Corsi di eco-drive",
		its: "6.14 Sistemi di trasporto intelligenti",
		route_planning: "6.15 Pianificazione dei percorsi",
		traffic_info: "6.16 Informazioni sul traffico", //L'ultima riga è bene che finisca con virgola
	},

	box7: {
		label: "BOX 7: Mobilità ridotta",
		accessibility_signs: "7.1 Wayfinding dedicato a persone con mobilità ridotta",
		infrastructure_mobility: "7.2 Sede accessibile per le persone con mobilità ridotta", //L'ultima riga è bene che finisca con virgola
	},

	box8: {
		label: "BOX 8: Ulteriori misure di mobilità",
		financial_incentives: "8.1 Incentivi economici",
		taxi_service: "8.2 Ritorno a casa garantito (servizio taxi)",
		trip_advice: "8.3 Servizio di consulenza individuale sugli spostamenti",
		flexibility: "8.4 Flessibilità di orario lavorativo",
		teleworking: "8.5 Smart working",
		mobility_office: "8.6 Sportello mobilità",
		land_use: "8.7 Sviluppo futuro e pianificazione degli spostamenti",
		e_shopping: "8.8 Abilitazione servizi e-commerce",
		general_services: "8.9 Fornitura di servizi aggiuntivi in loco", //L'ultima riga è bene che finisca con virgola
	},
};

//Qui inseriamo tutti i commenti sotto i campi dei form
const descriptions = {
	company_name: "Digita qui il nome dell'azienda a cui si riferisce il Mobility Label",
	survey_date: "Imposta la data a cui si riferisce il Mobility Label",
	box1: {
		label: null, //Se non c'è la description metti la proprietà, ma imposta a null
		accessible: "E' possibile raggiungere l'azienda in bici in modo sicuro e comodo (es. presenza di piste ciclabili, strade secondarie poco trafficate, ecc.).",
		theft_prevention: "L’azienda ha una chiara strategia e dispone di strutture e strumenti per prevenire il furto di biciclette presso la sede.",
		arrival_bike: "L’azienda dispone di spogliatoi, docce e armadietti per i dipendenti che si spostano in bicicletta.",
		internal_bikes: "L'azienda mette a disposizione dei propri dipendenti biciclette per gli spostamenti all'interno della sede e degli spostamenti per motivi di lavoro.",
		pool_bikes: "L'azienda possiede una flotta di biciclette che concede a comodato d'uso ai propri dipendenti per tutti i loro spostamenti anche quelli non legati al lavoro.",
		cycling_business_trips: "L’azienda promuove l'uso della bicicletta per gli spostamenti operativi dei propri dipendenti.",
		bike_sharing:
			"La sede aziendale è servita da uno o più servizi di bike sharing (es. presenza di stazioni di bike sharing vicini alla sede oppure la sede rientra nell'area di copertura del servizio).",
		internal_cycling: "E’ facile e sicuro spostarsi in bici all’interno della sede e l’azienda offre strumenti/servizi per la manutenzione di biciclette.",
		cycling_infrast:
			"L'azienda è a conoscenza dell’attuale rete ciclabile nelle sue vicinanze e collabora con le autorità locali al fine di creare nuovi collegamenti e migliorare e rendere più sicuri quelli esistenti.",
	},

	box2: {
		label: null,
		azessible: "E' possibile raggiungere l'azienda a piedi - l'azienda è situata a meno di 2 km da aree abitate o da fermate del trasporto pubblico.",
		arrival_walk: "L’azienda dispone di spogliatoi, docce e armadietti per i dipendenti che si spostano a piedi",
		infrastructure_walk:
			"L'azienda può essere raggiunta a piedi in sicurezza, es. sono presenti marciapiedi e attraversamenti sicuri (rispetto all'area urbana, alle fermate del trasporto pubblico, ecc.) è ritenuto sicuro.",
	},

	box3: {
		label: null,
		bus_accessible:
			"L'azienda è facilmente raggiungibile con il trasporto pubblico, es. presenza di fermate vicine, servizio con frequenze e orari dei mezzi compatibili con gli spostamenti casa-lavoro dei dipendenti.",
		bus_info: "L'azienda fornisce informazioni aggiornate sul servizio di trasporto pubblico ai propri dipendenti, es. orari, mappe dei percorsi, APP, Welcome Kit, ecc.",
		co_financing: "L'azienda cofinanzia almeno del 5% gli abbonamenti annuali dei propri dipendenti per il trasporto pubblico (autobus, treni, tram, metro, ecc.).",
		bus_facilities:
			"Presenza di fermate/stazioni del trasporto pubblico nelle vicinanze (entro 10 minuti a piedi), con pensiline, strutture per sedersi, vendita di biglietti ecc. ",
		lobby: "L'azienda ha una chiara idea delle esigenze di mobilità dei propri dipendenti e collabora con gli enti locali per promuove politiche analoghe.",
		bus_infrastructure:
			"Gli spostamenti a piedi all’interno della sede sono sicuri e facilitati in quanto i dipendenti che utilizzano i mezzi pubblici devono coprire l’ultima parte dello spostamento per raggiungere il proprio posto di lavoro a piedi.",
		shuttle_bus: "L'azienda ha un servizio di navetta per il collegamento con la rete di forza (stazioni/fermate treno/metropolitana) in caso si trova lontano da questa.",
		vanpooling: "L'azienda offre un servizio di vanpooling – con furgoni di proprietà oppure noleggiati",
	},

	box4: {
		label: null,
		matching_service:
			"L'azienda promuove e facilita la formazione di equipaggi di carpooling mettendo a disposizione strumenti analoghi, es. piattaforme online, bacheca di carpooling, ecc.",
		parking_carpooling: "L'azienda dispone di sosta riservata per chi fa carpooling",
		carpooling_incentives: "L'azienda offre incentivi, economici e/o non-economici, per promuovere il carpooling",
	},

	box5: {
		label: null,
		carsharing:
			"La sede aziendale è servita da uno o più servizi di car sharing (es. presenza di stalli car sharing vicini alla sede e/o la sede rientra nell'area di copertura del servizio di car sharing).",
		carsharing_convenzioni:
			"L'azienda facilita l'utilizzo del car sharing aderendo ad uno o più servizi esistenti (es. l'azienda  ha stipulato convezioni con gestori di car sharing).",
		bike_sharing:
			"La sede aziendale è servita da uno o più servizi di bike sharing (es. presenza di stalli bike sharing vicini alla sede e/o la sede rientra nell'area di copertura del servizio di bike sharing).",
		bike_sharing_convenzioni:
			"L'azienda facilita l'utilizzo del bike sharing aderendo ad uno o più servizi esistenti (es. l'azienda  ha stipulato convezioni con gestori di bike sharing).",
		scooter_sharing: "La sede aziendale è servita da uno o più servizi di sccoter sharing (es. la sede rientra nell'area di copertura del servizio di scooter sharing).",
		scooter_sharing_convenzioni:
			"L'azienda facilita l'utilizzo dello scooter sharing aderendo ad uno o più servizi esistenti (es. l'azienda  ha stipulato convezioni con gestori di scooter sharing).",
		monopattini:
			"La sede aziendale è servita da uno o più servizi di  uno o più servizi di monopattini elettrici (es. la sede rientra nell'area di copertura del servizio di monopattini elettrici).",
		monopattini_convenzioni:
			"L'azienda facilita l'utilizzo dei monopattini elettrici aderendo ad uno o più servizi esistenti (es. l'azienda  ha stipulato convezioni con gestori di monopattini elettrici).",
		incentives_sharing: "L'azienda offre incentivi, economici e/o non-economici, ai dipendenti per promuovere l'utilizzo della sharing mobility.",
	},

	box6: {
		label: null,
		accessibility: "L'azienda è facilmente raggiungibile in auto",
		business_trips: "L’azienda adotta politiche per ridurre il numero di viaggi per lavoro (es. teleconferenze).",
		efficient_use_work: "L’azienda promuove un uso più efficiente delle auto per gli spostamenti per lavoro o l'uso di mezzi alternativi.",
		sensible_car_use:
			"L’azienda possiede una flotta di veicoli messi a disposizione dei dipendenti per gli spostamenti casa-lavoro oppure spostamenti non legati al lavoro (individuali o collettivi).",
		park_ride: "L’azienda propone un servizio di Park&Ride ai propri dipendenti, promuovendo l'uso di posteggi di interscambio e prosecuzione del viaggio con mezzi pubblici.",
		parking_fee: "L’azienda applica un sistema di pagamento della sosta presso la sede.",
		parking_management:
			"L’azienda adotta un piano di gestione della sosta presso la sede in base alle esigenze di mobilità dei suoi dipendenti e in linea con la sua strategia di mobilità sostenibile / PSCL.",
		car_free: "Nelle are di pertinenza  della sede sono definite zone car-free",
		access_management: "L'azienda favorisce l’accesso alla sede di certi tipi/modalità di trasporto, es. carpoolers, auto elettriche.",
		vhl_restrictions: "L’azienda è localizzata in una zona (o applica essa stessa) con restrizioni di accesso in base alle emissioni dei veicoli.",
		clean_vhls: "L’azienda possiede una flotta aziendale costituita da veicoli a carburanti alternativi, auto elettriche oppure a bassa emissione.",
		alternative_fuels:
			"L’azienda ha una politica per incentivare l'uso di veicoli alimentati con carburanti alternativi da parte dei dipendenti, es. installazione di colonnine per auto elettriche.",
		eco_driving: "L’azienda promuove l'eco-drive con specifici corsi per i propri dipendenti e/o incentivi per chi adotta questa modalità di guida.",
		its: "L'azienda utilizza sistemi di trasporto intelligenti per la sua flotta di veicoli e per l'accesso all'area di pertinenza (es: indicatori di posteggio, monitoraggio inquinanti, etc.).",
		route_planning: "L’azienda utilizza dei navigatori in grado di soluzioni multimodali (es: MaaS) per invitare a ridurre l'uso dell'auto in favore di mezzi alternativi",
		traffic_info: "L’azienda offre ai dipendenti un servizio/software che fornisce informazioni sul traffico in tempo reale e possibili alternative in caso di traffico.",
	},

	box7: {
		label: null,
		accessibility_signs: "L’azienda ha adottato sistemi di orientamento per le persone con mobilità ridotta (es. segnaletica).",
		infrastructure_mobility: "L'azienda dispone di infrastrutture che garantiscono il facile accesso delle persone con mobilità ridotta.",
	},

	box8: {
		label: null,
		financial_incentives: "L'azienda offre incentivi economici ai dipendenti che si spostano con mezzi di trasporto sostenibili.",
		taxi_service: "L’azienda offre un servizio di 'ritorno a casa garantito' per i dipendenti che utilizzano modi di trasporto sostenibili.",
		trip_advice:
			"L'azienda offre ai propri dipendenti consulenza personale sulla pianificazione e ottimizzazione dello spostamento casa-lavoro favorendo soluzioni di mobilità sostenibile.",
		teleworking: "I dipendenti hanno la possibilità di fare smart working.",
		flexibility: "I dipendenti hanno orari di lavoro flessibili.",
		mobility_office: "La società ha istituito uno Sportello Mobilità per i suoi dipendenti con lo scopo di facilitare e promuovere la mobilità sostenibile.",
		land_use: "Il tema della mobilità sostenibile è presente alla pianificazione futura e potenziale espansioni e piani di sviluppo.",
		e_shopping: "L’azienda offre servizi e-commerce dedicati ai suoi dipendenti, es. spesa a domicilio o in azienda.",
		general_services: "I dipendenti possono usufruire di una vasta gamma di servizi in loco, es. asilo nido, servizio di farmacia, servizio di lavanderia ecc.",
	},
};

export default {
	name: "MobilityLabelEdit",
	mixins: [Util],
	data() {
		return {
			form: null,
			loaded: false,
			office_name: {},
			company_name: {},
			office_id: this.$route.params.office_id,
			redirect_to: this.$route.query.redirect_to,
			labels: labels, //const
			descriptions: descriptions, //const
			label_result: {
				box1: 0,
				box2: 0,
				box3: 0,
				box4: 0,
				box5: 0,
				box6: 0,
				box7: 0,
				box8: 0,
				total: 0,
				class1: "--",
				class2: "--",
				class3: "--",
				class4: "--",
				class5: "--",
				class6: "--",
				class7: "--",
				class8: "--",
				class_total: "--",
			},
		};
	},
	async created() {

		this.office_id = this.$route.params.office_id;
		if (this.office_id === undefined) {
			this.showError("office_id is required");
			return;
		};

		try {
			MobilityLabelService.view(this.office_id)
				.then((response) => {
					this.form = response.data.labelSurvey;
					//console.log("lettura", this.form);	
					if (this.form.survey_date === undefined) {
						this.form = {...this.form, 
							...{ 
							"box1": { 
								"bike_ride": 0, "accessible": 0, "pool_bikes": 0, "arrival_bike": 0, "internal_bikes": 0, "cycling_infrast": 0, "internal_cycling": 0, "theft_prevention": 0, "cycling_business_trips": 0 
							}, 
							"box2": { 
								"azessible": 0, "arrival_walk": 0, "infrastructure_walk": 0 
							}, 
							"box3": {
								 "lobby": 0, "bus_info": 0, "vanpooling": 0, "shuttle_bus": 0, "co_financing": 0, "bus_accessible": 0, "bus_facilities": 0, "bus_infrastructure": 0 
								}, 
							"box4": { 
								"matching_service": 0, "parking_carpooling": 0, "carpooling_incentives": 0 
							}, 
							"box5": {
								 "carsharing": 0, "carsharing_location": 0, "incentives_carsharing": 0 
								}, 
							"box6": {
								 "its": 0, "car_free": 0, "park_ride": 0, "clean_vhls": 0, "eco_driving": 0, "parking_fee": 0, "traffic_info": 0, "accessibility": 0, "business_trips": 0, "route_planning": 0, "sensible_car_use": 0, "vhl_restrictions": 0, "access_management": 0, "alternative_fuels": 0, "efficient_use_work": 0, "parking_management": 0 
								}, 
							"box7": {
								 "accessibility_signs": 0, "infrastructure_mobility": 0
								 }, 
							"box8": {
								 "land_use": 0, "e_shopping": 0, "teleworking": 0, "trip_advice": 0, "taxi_service": 0, "mobility_office": 0, "general_services": 0, "financial_incentives": 0 }, 
							}};
					}
					this.company_name = this.form.company_name;
					this.office_name = this.form.office_name;
					this.form.survey_date= Date.now();
					this.loaded = true;
					//console.log("pre-fill", this.form);	
					
				})
				.catch((error) => {
					this.showError(error);
				});

		} catch (e) {
			console.log(e);
			this.$bvToast.toast(e.message, {
				title: "Errore",
				autoHideDelay: 2000,
				appendToast: true,
				variant: "danger",
			});
		}
	},
	methods: {
		async submit() {
			try {
				//console.log("pre-save", this.form);	
				await MobilityLabelService.edit(this.office_id, this.form);
				this.$bvToast.toast("Salvataggio avvenuto correttamente", {
					title: "Salvataggio ok",
					autoHideDelay: 2000,
					appendToast: true,
				});
				if (this.redirect_to) {
					setTimeout(() => {
						// set a timeout to let the toast appear before redirecting
						this.$router.push(this.redirect_to);
					}, 500);
				}
			} catch (e) {
				this.$bvToast.toast(e.message, {
					title: "Errore",
					autoHideDelay: 2000,
					appendToast: true,
					variant: "danger",
				});
			}
		},
	},
};
</script>
<style>
.description {
	font-size: smaller;
	display: block;
	padding-bottom: 1em;
	padding-left: 0.5em;
}
</style>
