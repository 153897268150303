const comuni_mi = [
	{ name: "Milano", zona: 1 },
	{
		name: "Milano Linate Aeroporto",
		zona: 8,
		zona_id: "mi3-mi4",
	},
	{
		name: "Milano Cascina Gobba M2",
		zona: 8,
		zona_id: "mi3-mi4",
	},
	{ name: "Rho Fiera Milano M1", zona: 8, zona_id: "mi3-mi4" },
	{ name: "San Donato M3", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Sesto Marelli M1", zona: 8, zona_id: "mi3-mi4" },
	{
		name: "Cinisello Monza Bettola",
		zona: 8,
		zona_id: "mi3-mi4",
	},
	{ name: "Abbiategrasso", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Agrate Brianza", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Aicurzio", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Albairate", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Albiate", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Arconate", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Arcore", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Arese", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Arluno", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Assago", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Baranzate", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Bareggio", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Barlassina", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Basiano", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Basiglio", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Bellinzago Lombardo", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Bellusco", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Bernareggio", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Bernate Ticino", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Besana in Brianza", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Besate", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Biassono", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Binasco", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Boffalora sopra Ticino", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Bollate", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Bovisio-Masciago", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Bregnano", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Bresso", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Briosco", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Brugherio", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Bubbiano", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Buccinasco", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Burago di Molgora", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Buscate", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Busnago", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Bussero", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Busto Arsizio", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Busto Garolfo", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Cabiate", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Calusco d'Adda", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Calvignasco", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Cambiago", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Camparada", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Canegrate", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Cantù", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Caponago", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Carate Brianza", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Carimate", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Carnate", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Caronno Pertusella", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Carpiano", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Carugate", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Casalmaiocco", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Casarile", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Casatenovo", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Casorate Primo", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Casorezzo", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Cassano d'Adda", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Cassina de' Pecchi", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Cassinetta di Lugagnano", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Castano Primo", zona: 28, zona_id: "mi8-mi9" },
	{ name: "Castellanza", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Cavenago di Brianza", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Ceriano Laghetto", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Cermenate", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Cernusco Lombardone", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Cernusco sul Naviglio", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Cerro al Lambro", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Cerro Maggiore", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Cesano Boscone", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Cesano Maderno", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Cesate", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Cinisello Balsamo", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Cisliano", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Cogliate", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Cologno Monzese", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Colturano", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Comazzo", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Concorezzo", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Corbetta", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Cormano", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Cornaredo", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Cornate d'Adda", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Correzzana", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Corsico", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Cuggiono", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Cusago", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Cusano Milanino", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Dairago", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Desio", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Dresano", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Figino Serenza", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Gaggiano", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Garbagnate Milanese", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Gessate", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Giussano", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Gorgonzola", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Grezzago", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Gudo Visconti", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Inveruno", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Inzago", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Lacchiarella", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Lainate", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Lazzate", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Legnano", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Lentate sul Seveso", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Lesmo", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Limbiate", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Liscate", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Lissone", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Locate di Triulzi", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Macherio", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Magenta", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Magnago", zona: 28, zona_id: "mi8-mi9" },
	{ name: "Marcallo con Casone", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Mariano Comense", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Masate", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Meda", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Mediglia", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Melegnano", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Melzo", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Merate", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Merlino", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Mesero", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Mezzago", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Misinto", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Monticello Brianza", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Monza", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Morimondo", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Motta Visconti", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Muggiò", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Mulazzano", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Nerviano", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Nosate", zona: 28, zona_id: "mi8-mi9" },
	{ name: "Nova Milanese", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Novate Milanese", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Novedrate", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Noviglio", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Olgiate Molgora", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Opera", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Origgio", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Ornago", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Osnago", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Ossona", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Ozzero", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Paderno d'Adda", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Paderno Dugnano", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Pantigliate", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Parabiago", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Paullo", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Pero", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Peschiera Borromeo", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Pessano con Bornago", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Pieve Emanuele", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Pioltello", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Pogliano Milanese", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Pozzo d'Adda", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Pozzuolo Martesana", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Pregnana Milanese", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Renate", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Rescaldina", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Rho", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Robbiate", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Robecchetto con Induno", zona: 28, zona_id: "mi8-mi9" },
	{ name: "Robecco sul Naviglio", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Rodano", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Roncello", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Ronco Briantino", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Rosate", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Rovellasca", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Rozzano", zona: 8, zona_id: "mi3-mi4" },
	{ name: "San Donato Milanese", zona: 8, zona_id: "mi3-mi4" },
	{ name: "San Giorgio su Legnano", zona: 23, zona_id: "mi6-mi7" },
	{ name: "San Giuliano Milanese", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Santo Stefano Ticino", zona: 19, zona_id: "mi5-mi6" },
	{ name: "San Vittore Olona", zona: 19, zona_id: "mi5-mi6" },
	{ name: "San Zenone al Lambro", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Saronno", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Sedriano", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Segrate", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Senago", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Seregno", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Sesto San Giovanni", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Settala", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Settimo Milanese", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Seveso", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Solaro", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Sordio", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Sovico", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Sulbiate", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Tavazzano con Villavesco", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Treviglio", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Trezzano Rosa", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Trezzano sul Naviglio", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Trezzo sull'Adda", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Tribiano", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Triuggio", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Truccazzano", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Turbigo", zona: 28, zona_id: "mi8-mi9" },
	{ name: "Uboldo", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Usmate Velate", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Vanzaghello", zona: 28, zona_id: "mi8-mi9" },
	{ name: "Vanzago", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Vaprio d'Adda", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Varedo", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Vedano al Lambro", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Veduggio con Colzano", zona: 26, zona_id: "mi7-mi8" },
	{ name: "Verano Brianza", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Verderio", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Vermezzo", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Vernate", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Vignate", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Villa Cortese", zona: 23, zona_id: "mi6-mi7" },
	{ name: "Villasanta", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Vimercate", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Vimodrone", zona: 8, zona_id: "mi3-mi4" },
	{ name: "Vittuone", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Vizzolo Predabissi", zona: 14, zona_id: "mi4-mi5" },
	{ name: "Zelo Buon Persico", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Zelo Surrigone", zona: 19, zona_id: "mi5-mi6" },
	{ name: "Zibido San Giacomo", zona: 8, zona_id: "mi3-mi4" },
];

export default comuni_mi;
