<template>
    <div>
    <h2>La tessera “Io Viaggio” non è una tessera “Io Viaggio Mobility”</h2>
    <p style="font-size: 1.5em;">Se in passato hai acquistato un abbonamento Trenord privatamente (e non tramite lo Sportello
        Abbonamenti) probabilmente sei già in possesso di una tessera Io Viaggio.
    </p>
    <p>
        🥲 Purtroppo la tessera Io Viaggio non può essere utilizzata per l’acquisto degli abbonamenti tramite la
        convenzione aziendale, per i quali è necessario essere in possesso della tessera Io Viaggio Mobility.
    </p>
    <p>
        🙂 Se hai già acquistato in passato un abbonamento annuale tramite lo Sportello Abbonamenti sei già in
        possesso di una tessera Io Viaggio Mobility.
    </p>

    <h2>Hai una Tessera Io Viaggio Mobility</h2>

    <b-form-group required>
        <b-form-radio v-model="form.tessera_io_viaggio_mobility" name="tessera_tpl" :value="true" required>Sì</b-form-radio>
        <!--:value in questo modo il programma capisce lo "0" come valore e non parola-->
        <b-form-radio v-model="form.tessera_io_viaggio_mobility" name="tessera_tpl" :value="false" required>No</b-form-radio>
    </b-form-group>

    <b-img v-if="form.tessera_io_viaggio_mobility == true" :src="`${apiPath}/abbonamenti/tessera-trenord.png`" alt="Modello Tessera Trenord">

    </b-img>
    <b-form-group v-if="form.tessera_io_viaggio_mobility == true" label="Indica il tuo numero di tessera" required>
        <b-form-input placeholder="00000-000000000" required v-model="form.numero_ioviaggio_mobility"></b-form-input>        
    </b-form-group>
    <b-form-group v-if="form.tessera_io_viaggio_mobility == true" label="Indica il tuo codice cliente (holder id)" required>        
        <b-form-input placeholder="000000000" required v-model="form.codice_cliente_trenord" name="codice_cliente_trenord"></b-form-input>
    </b-form-group>
    <b-form-group v-if="form.tessera_io_viaggio_mobility == true" label="Scadenza Tessera" required>        
        <b-form-input type="date" required v-model="form.scadenza_tessera_trenord" name="scadenza_tessera_trenord"></b-form-input>
    </b-form-group>
    <b-form-group v-if="form.tessera_io_viaggio_mobility == false" >
        Nessuna preoccupazione, acquisteremo noi la tessera per tuo conto.
    </b-form-group>
    </div>
</template>

<script>
    export default {
        name: "AbbonamentoIoViaggio",
        props: {
            form: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                apiPath: process.env.VUE_APP_ROOT,
            };
        },
    };
</script>