<template>
    <div class="row">
        <div class="col-12">
            <iframe src="//www.sistemapiemonte.it/carpool/app/webroot/index.php/journeys/search?layout=embed" width="100%" height="600"></iframe>
        </div>
    </div>
</template>
<script>
export default {
	data() {},
};
</script>
<style></style>
