<template>
    <div>
        <h2>Scegli l'operatore di trasporto</h2>
        <b-card-group deck>
            <b-card v-for="operatore in operatori" :key="operatore.title" :title="operatore.title"
                :img-src="`${apiPath}/abbonamenti/${operatore.img}`" :img-alt="operatore.title" img-top tag="article"
                style="max-width: 15rem" class="mb-2">
                <b-card-text>
                    {{ operatore.text }}
                </b-card-text>

                <template #footer>
                    <b-button v-if="operatore.next != ''" href="#" variant="primary" class="float-right mb-1"
                        @click="nextTab(operatore.next)">Scegli</b-button>
                    <b-button v-if="operatore.next == ''" href="#" variant="primary" class="float-right mb-1"
                        disabled>In Arrivo</b-button>
                </template>
            </b-card>
        </b-card-group>
        <h4>Riepilogo Richieste</h4>
        <router-link to="/pendolare/riepilogo">
            <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
            Vai al Riepilogo delle tue richieste
        </router-link>
    </div>
</template>

<script>
import Util from "@/mixing/util";
import operatori from "../data/operatori";


export default {
    name: "Abbonamenti",
    mixins: [Util],
    data() {
        return {
            operatori: operatori, //Elenco di operatori supportati
        };
    },
    computed: {},
    methods: {
        nextTab(next) {
            this.$router.push(`/pendolare/${next}`);
        },
    },
    async created() {

    }
};
</script>

<style>
.card-img-top {
    padding: 1em;
}
</style>
