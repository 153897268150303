<template>
    <div>
		<b-overlay :show="isBusy" rounded="sm">
		<div v-if="users.length==0">
			<h2>Non ci sono ancora risposte a questo questionario</h2>
		</div>
        <b-table :items="users" :fields="['user_id', 'modified', 'origin.geocoded_at', 'actions']" v-else>
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>

            <template v-slot:cell(user_id)="row">
                <router-link :to="`/questionari/answers/${survey_id}/${row.item.user_id}`">
                    {{ niceName(row.item) }}
                </router-link>
            </template>

            <template v-if="origin" v-slot:cell(origin)="row">
                <b-badge v-if="row.item.origin.geocoded_at" variant="success" :title="row.item.origin.geocoded_at">
                    <span class="ti-world"></span> {{ row.item.origin.address }}
                </b-badge>
                <b-badge v-else variant="danger">{{ row.item.origin.address }} </b-badge>
                <span class="ti-pencil" @click="editAddress()"></span>
            </template>

            <template v-slot:cell(actions)="row">
                <b-link class="action" @click.prevent="del_answers(survey_id, row.item.user_id)">Elimina</b-link>
            </template>
        </b-table>
		</b-overlay>
    </div>
</template>

<script>
import UserService from "@/services/user.service";
import Util from "@/mixing/util";

export default {
	name: "QuestionariRisposte",
	mixins: [Util],
	data() {
		return {
			isBusy: true,
			survey_id: null,
			users: [],
			origin: null,
		};
	},
	methods: {
		del_answers(survey_id, user_id) {
			this.$bvModal.msgBoxConfirm(`Vuoi davvero cancellare le risposte dell'utente ${user_id}? `, {})
				.then(async (value) => {
					if (value) {
						let response = await UserService.deleteAnswers(survey_id, user_id);
						this.$bvToast.toast(response.data.msg, {
							title: "Info",
							autoHideDelay: 2000,
							appendToast: true,
							variant: "info",
						});
						this.fetchUsers();
					}				
				});
		},
		async fetchUsers() {
			this.isBusy = true;
			this.survey_id = this.$route.params.survey_id;

			UserService.getAnswerUsersList(this.survey_id)
				.then((response) => {
					this.users = response.data.users;
					this.isBusy = false;
				})
				.catch((error) => {
					//console.log(error);
					this.isBusy = false;
				});
		},
		niceName(item) {
			let anonimo = true;

			if (!item.user){
				return "";
			}
			if (!item.user.hasOwnProperty("first_name")){
				item.user.first_name = "";
			}
			if (!item.user.hasOwnProperty("last_name")){
				item.user.last_name = "";
			}
			if (item.user.first_name + item.user.last_name == "") {
				return "Anonimo-" + item.user_id.substring(1, 4);
			} else {
				if (anonimo){
					return "Anonimo-" + item.user_id.substring(1, 4);
				}
				return `${item.user.first_name} ${item.user.last_name}`;
			}
		},
		editAddress() {
			alert("Qui potrai correggere l'indirizzo inserito dall'utente");
		},
	},
	computed: {},
	async created() {
		await this.fetchUsers();
	},
};
</script>

<style></style>
