<template>
	<div>
		<b-row>
			<b-breadcrumb class="col-md-10">
				<b-breadcrumb-item href="/">
					<b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
					Home
				</b-breadcrumb-item>
				<b-breadcrumb-item href="/abbonamenti">Scelta Operatore</b-breadcrumb-item>
				<b-breadcrumb-item active> Trenord </b-breadcrumb-item>
			</b-breadcrumb>
			<b-col class="col-md-2">
				<b-img :src="`${apiPath}/abbonamenti/trenord.jpg`" alt="Trenord" 
					style="max-height: 50px; align: middle"></b-img>
			</b-col>
		</b-row>

		<b-tabs content-class="mt-3" v-model="activeTab">
			<!-- step-2: selezione / calcolo dell'abbonamento-->
			<b-tab title="Tratta" id="step-2" value="step-2" card>
				<h2>Il Tuo Abbonamento</h2>
				<b-form ref="form-0">
					<b-row>
						<b-col cols="6">
							<p></p>
							<p>
								<b-icon icon="question-circle-fill" variant="success"></b-icon>
								Consulta le informazioni sugli
								<a href="https://trenord-europe-trenord-endpoint-prd.azureedge.net/fileadmin/contenuti/TRENORD/4-Info_e_assistenza/Informazioni_utili/Condizioni_di_trasporto/CONDIZIONI__DI_TRASPORTO_DOCUMENTI/AvvisoA3Trenord_2020_167_REV2__tariffario.pdf"
									target="blank">abbonamenti annuali Trenord</a>
							</p>
						</b-col>
					</b-row>

					<b-row>
						<!--si indica l'origine e la destinazione del tragitto senza calcollare l'abbonamento -->
						<div class="col-md-4 border-right">
							<h4 class="text-center">Indicare l'origine e la destinazione del tuo tragitto</h4>
							<b-form-group>
								<label for="origine"><b>*</b>Da quale città parti?</label>
								<b-input v-model="form.origine" :serializer="(s) => s.name" :data="comuni"
									placeholder="Inserici il comune da cui parti" id="origine" class="mb-2" required>
								</b-input>
								<label for="destinazione"><b>*</b>Qual è la tua città di destinazione?</label>
								<b-input v-model="form.destinazione" :serializer="(s) => s.name" :data="comuni"
									placeholder="Inserici il comune della tua destinazione" id="destinazione" class="mb-2"
									required>
								</b-input>
							</b-form-group>
							<p class="mt-3">*Campi obbligatori</p>
						</div>
						<!--fine indicazione origine destinazione -->
						<!-- Scelta abbonamento -->
						<div class="col-md-4">
							<b-overlay :show="loading" rounded="sm">
								<h4 class="text-center">Scegli il tuo abbonamento</h4>
								<b-form-select v-model="form.abbonamento_selezionato" required>
									<b-select-option v-for="(t, index) in tariffe" :key="index" :value="index"> {{ index }}
										- {{ t.price }} € </b-select-option>
								</b-form-select>
								<br /><br />

								<p>
									<b>Sei già in possesso di una tessera Mobility valida?</b>
								</p>
								<b-form-group required>
									<b-form-radio v-model="form.tessera_tpl" name="tessera_tpl" :value="1">Sì</b-form-radio>
									<!--:value in questo modo il programma capisce lo "0" come valore e non parola-->
									<b-form-radio v-model="form.tessera_tpl" name="tessera_tpl" :value="0">No</b-form-radio>
								</b-form-group>

								<b-form-group v-if="form.tessera_tpl == 1" label="Indica la stazione in cui ricarichi">
									<b-form-select v-model="form.stazione_ricarica_id" :options="stazioni_ricarica" required
										@change="aggiornaNomeStazioneRicarica()">
									</b-form-select>
								</b-form-group>
							</b-overlay>
						</div>
						<div class="col-md-4">
							<trenord-login></trenord-login>
						</div>
						<!-- Fine scelta abbonamento -->
					</b-row>

					<!-- end of seleziona abbonamento dall'elenco-->
					<div class="row mt-4">
						<!--qui viene visualizzato l'output del calcolo dell'abbonamento-->
						<div class="col-md-4">
							<p v-if="perc_carico_utente < 1">
								Ordina il tuo abbonamento annuale per il trasporto pubblico ad un
								<b>prezzo scontato</b> grazie al contributo aziendale.
							</p>
							<p><b>Non devi anticipare nulla</b>, il costo dell'abbonamento verrà distribuito <b>durante
									l'anno</b> in 12 trattenute sulla tua busta paga.</p>
						</div>
						<div class="col-md-4">
							<abbonamento-card :abbonamento_selezionato="form.abbonamento_selezionato"
								:prezzo_pieno="prezzo_pieno" :prezzo_scontato="prezzo_scontato">
							</abbonamento-card>
						</div>
					</div>
				</b-form>
				<b-card-footer class="d-flex flex-row-reverse">
					<b-button @click="nextTab()" variant="primary" >Avanti</b-button>
				</b-card-footer>
				<!-- end of calcola abbonamento-->
			</b-tab>
			<!-- end of step-2:selezione / calcolo dell'abbonamento-->

			<!-- step-3-->
			<b-tab title="Data Inizio" id="step-3" value="step-3" card>
				<h2>Data inizio validità</h2>
				<b-form ref="form-1">
					<p>Le date per richiedere il tuo abbonamento sono le seguenti</p>
					<b-list-group>
						<b-list-group-item variant="primary">
							<b-row>
								<b-col cols="10">
									<label for="mese_validita">Inizio validità dell'abbonamento</label>
									<b-form-checkbox v-model="form.mese_validita" :value="prossimo_to_YMD" required
										name="mese_validita">
										1 {{ num_to_mese(scegli_prossimo.validita_abbonamento) }}
										{{ num_to_anno(scegli_prossimo.validita_abbonamento) }}
									</b-form-checkbox>
								</b-col>
								<b-col cols="2" class="align-self-center">
									<b-button href="#" variant="primary" @click="nextTab">Avanti</b-button>
								</b-col>
							</b-row>
						</b-list-group-item>
					</b-list-group>
					<h4>Tempistiche previste dall'operatore</h4>

					<div class="row">
						<div class="col-md-6">
							<b-table striped hover :items="validita" :fields="['ordina_entro_il', 'validita_abbonamento']">
								<template #cell(ordina_entro_il)="data">
									{{ data.item.ordina_entro_il_gg }}
									{{ num_to_mese(data.item.ordina_entro_il_mm) }}
									{{ num_to_anno(data.item.ordina_entro_il_mm) }}
								</template>
								<!-- A custom formatted column -->
								<template #cell(validita_abbonamento)="data">
									1 {{ num_to_mese(data.value) }}
									{{ Math.max(num_to_anno(data.value), num_to_anno(data.item.ordina_entro_il_mm)) }}
								</template>
							</b-table>
						</div>
						<div class="col-md-6"></div>
					</div>
				</b-form>
				<b-card-footer class="d-flex flex-row-reverse">
					<b-button href="#" variant="primary" @click="nextTab" >Avanti</b-button>
				</b-card-footer>
			</b-tab>
			<!-- end of step-2-->

			<!-- step-4: dati aggiuntivi -->
			<b-tab title="I tuoi dati" id="step-4" value="step-4">
				<b-form ref="form-2">
					<abbonamento-user :form="form"></abbonamento-user>
				</b-form>
				<b-card-footer class="d-flex flex-row-reverse">
					<b-button variant="primary"  @click="nextTab()">Avanti</b-button>
				</b-card-footer>
			</b-tab>
			<!-- end of step-4 nuovo abbonamento -->

			<!-- step-5: riepilogo e submit-->
			<b-tab title="Riepilogo" id="step-5" value="step-5">
				<b-form ref="form-3">
					<abbonamento-riepilogo :form="form" :prezzo_pieno="prezzo_pieno"
						:prezzo_scontato="prezzo_scontato"></abbonamento-riepilogo>
				</b-form>
				<b-card-footer class="d-flex flex-row-reverse">
					<b-button variant="primary" class="mr-2 float-right" @click.prevent="submit()">
						<div v-if="loading">
							<b-spinner small></b-spinner>
							<span class="sr-only">Sto salvando...</span>
						</div>
						<div v-else>
							<i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
							Invia la richiesta (acquista)
						</div>
					</b-button>
				</b-card-footer>
			</b-tab>
			<!-- end of step-5: riepilogo e submit-->
		</b-tabs>
		<!-- end of acquisto abbonamento-->
	</div>
</template>

<script>
import Util from "@/mixing/util";
import MixinAbbonamenti from "../mixin.js";

import AbbonamentoCard from "../components/AbbonamentoCard";
import AbbonamentoUser from "../components/AbbonamentoUser";
import AbbonamentoRiepilogo from "../components/AbbonamentoRiepilogo";
import TrenordLogin from "../components/TrenordLogin";
import stazioni_ricarica from "../data/stazioni-ricarica-trenord";

export default {
	name: "Abbonamenti",
	components: {
		AbbonamentoCard,
		AbbonamentoUser,
		AbbonamentoRiepilogo,
		TrenordLogin,
	},
	mixins: [Util, MixinAbbonamenti],
	data() {
		return {			
			stazioni_ricarica: stazioni_ricarica,
		};
	},
	async created() {
		await this.fetchTplOperator("trenord");
	},
	
	methods: {		
		aggiornaNomeStazioneRicarica() {
			this.form.stazione_ricarica_nome = stazioni_ricarica.find((x) => x.value == this.form.stazione_ricarica_id).text;
		},	
	},
};
</script>

<style>
.card-img-top {
	padding: 1em;
}
</style>
