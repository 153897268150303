<template>
    <div class="page-holder bg-cover d-flex flex-column sticky-footer-wrapper cover-back">
        <b-container class="flex-fill">
            <b-card class="overflow-hidden mt-5" footer-tag="footer"
                :class="logo ? 'col-md-8 offset-md-2' : 'col-md-6 offset-md-3'">
                <b-overlay :show="loading" rounded="sm">
                    <div>
                        <h1>Single Sign On Airport Handling
                            <b-img :src="`${apiPath}/abbonamenti/ah.png`" alt="Airport Handling" height="50"></b-img>
                        </h1>
                        
                        <b-progress :value="value" :max="max" show-progress animated variant="success"></b-progress>
                        <h4>Privacy: <span class="small">Consenso al trattamento dei dati</span></h4>
                        <p>
                            I dati forniti alla piattaforma Mobility48 saranno gestiti da MobilitySquare solo per i servizi di
                            mobilità attivati di volta in volta dall'utente (es:
                            Sportello Abbonamenti). <br />
                            L'utente prende atto che per lo svolgimento dell'attività, i dati raccolti dovranno essere
                            comunicati ai seguenti soggetti:
                        </p>
                        <ul>
                            <li>Operatori del Trasporto Pubblico Locale scelti dall'utente</li>
                            <li>Azienda a cui appartiene l'utente - Ufficio Paghe e Ufficio del Personale</li>
                        </ul>
                        <a href="https://mobilitysquare.eu/privacy" class="text-muted float-right small">Privacy Policy
                            MobilitySquare</a>
                        <b-form-checkbox v-model="privacy" required name="privacy" id="privacy"> Ho preso atto e voglio
                            procedere</b-form-checkbox>
                        <b-button variant="primary" class="mt-3" @click="checkPrivacy()" :disabled="nogo">Procedo verso il
                            servizio</b-button>
                        <b-button variant="secondary" class="mt-3 float-right" @click="logout()" v-if="showLogout">Logout</b-button>
                    </div>
                </b-overlay>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import authService from "@/services/auth.service";
import User from "@/models/user";
import Util from "@/mixing/util.js";

export default {
	name: "AbbonamentiAirportHandling",
	mixins: [Util],
	data() {
		return {
			value: 50,
			max: 100,
			privacy: false,
			nogo: false,
			showLogout: false,
		};
	},
	computed: {},
	async created() {
		let token = this.$route.query.token;
		let msg = this.$route.query.msg;
		if (msg != null) {
			msg = msg.replace(/<\/?[^>]+(>|$)/g, "");
			this.$bvModal.msgBoxOk(msg);
		}
		let user = new User("","");
		if (this.getUser && this.getUser.accessToken) {
			user = this.getUser;
		}
		this.value = 60;
		//this.nogo = true;
		if (token != null) {            
			try {
				user = await authService.ah(token, user);
				this.value = 100;                
				this.nogo = false;
				this.$router.push("/abbonamenti");
			} catch (error) {
				this.nogo = true;
				this.$bvModal.msgBoxOk(`L'utente richiesto non è presente su questa piattaforma.
                       Verificare la modalità di accesso con il proprio referente.\n\r                       
                       `);
			}            
		}
        
		if (user.accessToken != null) {
			this.showLogout = true;
		}
	},
	methods: {
		async checkPrivacy() {
			if (this.privacy == true) {                
				this.value = 100;                
				this.nogo = false;
				window.location.href =process.env.VUE_APP_ROOT + "/subscriptions/subscriptions/ah";                
			} else {
				alert("Per procedere è necessario accettare la privacy policy ed i termini del servizio");
			}
		},
	},
};
</script>
