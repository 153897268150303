//PDF Tariffe: https://www.atac.roma.it/files/doc.asp?r=1642

const tariffe_atac = {
	"Annule Roma Ordinario": {
		tariff_id: "RMO", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Annuale Roma Ordinario",
		price: 250,
	},
	"Annuale Lazio - 1 zona": {
		tariff_id: "1", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Lazio - 1 zona",
		price: 172,
	},
	"Annuale Lazio - 2 zone": {
		tariff_id: "2", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Lazio - 2 zone",
		price: 245.3,
	},
	"Annuale Lazio - 3 zone": {
		tariff_id: "3", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Lazio - 3 zone",
		price: 404,
	},
	"Annuale Lazio - 4 zone": {
		tariff_id: "4", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Lazio - 4 zone",
		price: 488.1,
	},
	"Annuale Lazio - 5 zone": {
		tariff_id: "5", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Lazio - 5 zone",
		price: 590.8,
	},
	"Annuale Lazio - 6-7 zone": {
		tariff_id: "6", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Lazio - 6-7 zone",
		price: 688.4,
	},
};

export default tariffe_atac;
