<template>
    <div v-if="monitoringNames.length > 0 && !busy">
        <h3>Monitorings</h3>
        <div v-for="name in monitoringNames" :key="name">
            <h4>{{ name }}</h4>
            <h6>Monitorings</h6>
            <b-table small :items="table.get(name)" :fields="labels"> </b-table>
            <div v-if="showChart">
                <h6>Impatto</h6>
                <b-table small :items="impactResults.get(name)" :fields="fields"> </b-table>
            </div>
        </div>
        <div v-if="showChart">
            <h4>Impact Charts</h4>
            <div v-for="name in pollutants" :key="name">
                <div>
                    <h7>Impact Chart {{ name }}</h7>
                    <GChart type="ColumnChart" :resizeDebounce="0" :data="pollutantImpactChartData.get(name)" :options="chartOptions" />
                    <h7>Impact Average Chart</h7>
                    <GChart type="BarChart" :resizeDebounce="0" :data="getMonitoringImpactAverage(name)" :options="chartOptions" />
                </div>
            </div>
        </div>
    </div>
</template>
serie
<script>
import Util from "@/mixing/util";
import { GChart } from "vue-google-charts";
export default {
	name: "Comparison",
	mixins: [Util],
	props: {
		measureId: Number,
		monitorings: Array,
		busy: Boolean,
		labels: Array,
		monitoringNames: Array,
		measure: Object,
	},
	components: {
		GChart,
	},
	data() {
		return {
			measure_id: null,
			fields: ["officeName", "riduzione_km_gg_auto", "C", "CO", "CO2", "NOx", "PM10"],
			pollutants: ["riduzione_km_gg_auto", "C", "CO", "CO2", "NOx", "PM10"],
			table: null,
			impactResults: null,
			pollutantImpactChartData: null,
			chartOptions: {
				title: null,
				titleTextStyle: {
					bold: false,
				},
				height: 700,
				width: 1300,
				vAxis: {
					title: "",
				},
				legend: { position: "right" },
			},
			showChart: true,
		};
	},
	methods: {
		calculateIndicator(serie) {
			if (serie == undefined) {
				return {};
			}
			let A = this.measure.indicator(serie);
			if (A == null) {
				return {};
			} else {
				return A.getOutput();
			}
		},
		getMonitoringByMonitoringName(name) {
			return this.monitorings.filter((el) => el.name == name);
		},
		createChartData(pollutant) {
			let chartData = [["MonitoringName", { role: "annotation" }, pollutant]];
			for (let [key, value] of this.impactResults) {
				let tmp = [];
				tmp.push(value[0]["monitoring"], value[0]["officeName"], value[0][pollutant]);
				chartData.push(tmp);
			}
			return chartData;
		},
		getMonitoringImpactAverage(pollutant) {
			let chartData = [["MonitoringName", pollutant]];
			for (let [key, value] of this.impactResults) {
				let tmp = [];
				tmp.push(key);
				let avg = value.map((el) => el["riduzione_km_gg_auto"]).reduce((sum, value, i, array) => sum + value / array.length, 0);
				tmp.push(avg);

				chartData.push(tmp);
			}
			return chartData;
		},
	},
	mounted() {
		this.table = new Map();
		this.impactResults = new Map();
		this.pollutantImpactChartData = new Map();
		let self = this;
		if (this.measure.indicator(this.monitorings[0]) == null) {
			this.showChart = false;
		}
		if (self.monitoringNames != null) {
			self.monitoringNames.forEach((name) => {
				let tmpMonitorings = self.getMonitoringByMonitoringName(name);
				self.table.set(name, tmpMonitorings);

				let tmpImpacts = [];
				tmpMonitorings.forEach((element) => {
					let rslts = self.calculateIndicator(element);
					rslts.officeName = element.officeName;
					tmpImpacts.push(rslts);
				});
				self.impactResults.set(name, tmpImpacts);
			});

			self.pollutants.forEach((pollutant) => {
				self.pollutantImpactChartData.set(pollutant, self.createChartData(pollutant));
			});
		}
	},
};
</script>
