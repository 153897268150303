<template>
    <div>
        <b-col>
            <!-- riepilogo dati abbonamento-->
            <b-card border-variant="primary" header="Il tuo abbonamento" header-bg-variant="success" header-text-variant="white" align="center">
                <b-card-text class="mt-2">
                    <p>Abbonamento: {{ abbonamento_selezionato }}</p>                   
                </b-card-text>
            </b-card>
            <!-- end of riepilogo dati abbonamento-->
        </b-col>
    </div>
</template>
<script>
export default {
	name: "abbonamentoCard",
	props: {
		abbonamento_selezionato: String,
		prezzo_pieno: Number,
		prezzo_scontato: Number,
	},
	computed: {
		sconto: function () {
			return parseFloat(this.prezzo_pieno) - parseFloat(this.prezzo_scontato) != 0;
		},
	},
};
</script>
<style></style>
