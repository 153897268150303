<template>
	<div>
		<b-card title="Aggiorna le tue credenziali trenord">
			
	    <trenord-login :force="true"></trenord-login>
		</b-card>
	</div>
</template>

<script>
import TrenordLogin from "../components/TrenordLogin";
import Util from "@/mixing/util";
import User from "@/models/user";
import AuthService from "@/services/auth.service";

export default {
	name: "TrenordLoginPage",
	components: {
		TrenordLogin,
		AuthService,
	},
	mixins: [Util],
	data() {
		return {
		};
	},
	methods: {
	},
	async created() {		
		let token = this.$route.query.token;
		console.log("token", token);
		let user = new User("", "");
		
		if (this.getUser && this.getUser.accessToken) {
			user = this.getUser;
		}
		this.value = 60;
		//this.nogo = true;
		if (token != null) {
			try {				
				user = await AuthService.ah(token, user);								
			} catch (error) {
				this.$bvModal.msgBoxOk(`L'utente richiesto non è presente su questa piattaforma.
                       Verificare la modalità di accesso con il proprio referente.\n\r                       
                       `);
			}
		}
	},
};

</script>