<template>
    <div>      
    <div class="container pb-5 justify-content-center" id="inizio">
    <h1>e-bike in città? I 5 elementi da valutare per capire se è la scelta giusta.</h1>    
    <div class="sticky-top">
    <div id="stick" class="row" style="border-bottom: 1px solid silver; top: 0px; z-index:1000; background-color: white; padding-bottom:5px">
        <div class="col col-md-8 col-12">
            <h6 class="pt-2">Convenzione Bici Acquisto e-bike per Sisal.</h6>
            <p class="pt-1" style="font-size: 90%">Senza impegno, ricevi uno sconto con cui potrai acquistare la tua e-bike usata, dopo averla provata.</p>
            <p class="text-primary">Per i dipendenti SISAL è riservato un listino speciale che prevede uno sconto di 50€ di una e-bike usata sul sito BikeSquare.</p>
        </div>
        <div class="col col-md-4  col-12">
            <a href="https://forms.gle/iAEKuvgSNinkwepf7" target="_blank" class="btn btn-success btn-lg mt-3">Richiedi buono sconto di 50€</a>                        
        </div>
    </div>
    </div>

    <h2>1 - Vuoi restare in forma</h2>
    <div class="row">
        <div class="col-md-8">
            <p class="pb-2 lead text-primary">
                Fai un lavoro sedentario? La tua giornata si alterna tra scrivania e sala riunioni? Spesso lavori da casa e ti muovi ancora meno?
                Hai provato ad iscriverti in palestra, ma poi tra impegni e pigrizia, l'esperienza non ha avuto grande successo?

            </p>
            <p class="pb-2">
                In questo caso l'e-bike fa per te. <b>Una attività fisica moderata ma continuativa, che puoi fare tutti i giorni anche se non sei allenato, ti permette di tenerti in forma e di usare il tempo del tuo viaggio per accelerare i battiti cardiaci (ma non troppo), per stimolare la circolazione e bruciare calorie.</b>
            </p>

        </div>

        <div class="col-md-4">
            <ul class="">
                <li>
                    <i class="fa fa-arrow-circle-right" aria-hidden="true"></i> Uno studio dell'università di Glasgow (su un campione di 265.000 lavoratori) ha dimostrato che chi va al lavoro in maniera attiva dimezza il rischio di cancro e malattie cardiache rispetto a chi usa l'auto.
                </li>
                <li>
                    <i class="fa fa-arrow-circle-right" aria-hidden="true"></i> L'OMS raccomanda agli adulti di fare tra 150 e 300 minuti di attività fisica moderata (tra cui l'e-bike) alla settimana. Una pedalata di mezz'ora al giorno (la strada che fai per andare al lavoro) ti permette di raggiungere l'obiettivo e di tenerti in forma.
                </li>
                <li>
                    <i class="fa fa-arrow-circle-right" aria-hidden="true"></i> Uno studio di Fietsenwinkel.nl ha verificato che con un'ora di pedalata in e-bike (assistenza media) si bruciano tra le 200 e le 300 Kcal, non proprio come andare in palestra, ma sempre meglio che non fare nulla.
                </li>
            </ul>
        </div>
    </div>


    <h2>2 - Lavori tra i 2 e 15 Km da casa, anche in salita</h2>
    <div class="row">
        <div class="col-md-8">
            <p class="pb-2 lead text-primary">
                Questa è la distanza che tutti riescono a percorrere con una e-bike senza essere particolarmente allenati e senza arrivare troppo sudati al lavoro.
            </p>
            <p class="pb-2">
                In e-bike impieghi tra i 15 e i 45 minuti per percorrere questa distanza. Non è un tempo confrontabile con quello che impieghi in auto? Ma vuoi mettere l'energia che hai quando scenti al lavoro dopo una pedalata, invece di aver guidato?
            </p>
        </div>

        <div class="col-md-4">
            <p class="pb-2">
                Se abiti molto più lontano, forse la e-bike non fa per te. O forse puoi valutare se puoi lasciarla in stazione (molte stazioni hanno dei posteggi sicuri, in cui sai di ritrovare la bici il giorno dopo).
            </p>
        </div>
    </div>


    <h2>3- Hai un posto sicuro dove ritirarla alla sera </h2>
    <div class="row">
        <div class="col-md-8">
            <p class="pb-2 lead text-primary">
                La e-bike è un mezzo abbastanza prezioso, ma se non hai una cantina, un garage o un cortile chiuso dove ritirarla la sera, forse non è una buona idea comprarne una.
            </p>
            <p class="pb-2">
                Noi abbiamo dei lucchetti che ti assicurano sul furto fino a 1500€. E abbiamo sviluppato un Kit GPS integrato nella bici che ti mostra in ogni momento la posizione e fa accendere il motore solo a te. <br>
                <mark>Ma in ogni caso, un luogo riparato è indispensabile.</mark>
            </p>
        </div>

        <div class="col-md-4">
            <img :src="`${apiPath}/bici/lucchetto-gps.jpg`" alt="Lucchetto e GPS" class="img-fluid">
        </div>
    </div>


    <h2>4- Hai a cuore l'ambiente e vuoi risparmiare sul carburante</h2>
    <div class="row">
        <div class="col-md-8">
            <p class="pb-2 lead text-primary">
                Risparmiare sul carburante è interessante per te e per gli altri.
            </p>
            <p class="pb-2">
                Se, per esempio, lavori a 5Km da casa e ci vai in auto, saprai bene che spendi (solo di carburante), circa 50-100€ al mese, senza contare posteggio, usura, etc etc.<br>
            </p>
            <p class="pb-2">
                Se ci vai in e-bike il risparmio è incredibile. La ricarica ti <mark>costa 15 centesimi ogni 50Km</mark> Quindi con meno di un euro viaggi per tutto il mese. E non ci sono costi di posteggio, bollo e assicurazione.
            </p>
            <p class="pb-2">
                Le tue emissioni si azzerano (in particolare se usi energia elettrica prodotta da fonti rinnovabili, come facciamo noi).
            </p>
        </div>

        <div class="col-md-4">
            <img :src="`${apiPath}/bici/bici-lavoro-sml.jpeg`" alt="Lucchetto e GPS" class="img-fluid">
        </div>
    </div>



    <h2>5 - Devi accompagnare un figlio a scuola, a calcio e teatro. </h2>
    <div class="row">
        <div class="col-md-8">
            <p class="pb-2 lead text-primary">
                Se il bambino pesa meno di 25Kg (quindi fino ai primi anni delle elementari), puoi metterlo su un seggiolino e portarlo con facilità anche per tratti un po' più lunghi del solito.
            </p>
            <p class="pb-2">
                La bici a pedalata assistita ti aiuta fino a 25Km/h e ti permette di pedalare veloce senza fare troppo sforzo, anche se hai la borsa del computer, la spesa ed il seggiolino.
            </p>
        </div>

        <div class="col-md-4">
            Ti racconto la storia di Alessandro, di Roma. Lui abita in centro ed ha 3 figli che deve accompagnare a scuola e a fare calcio. Da quando ha dimenticato l'auto gli è cambiata la vita. Ha preso una cargo-bike elettrica e risparmia un sacco di tempo per spostarsi e cercare posteggio, di benzina. E poi ha insegnato ai suoi figli a muoversi in città e passa del tempo divertenete con loro.
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-4">
            <div class="card text-white bg-primary mb-3" style="max-width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Provaci ancora, Sam</h5>
                    <p class="card-text">Magari nel passato hai già provato ad andare al lavoro in bici, ma ti era sembrato faticoso? O pericoloso?</p>
                    <p class="pt-4">
                        <b>Ti assicuro che con una e-bike è tutta un'altra vita. E negli ultimi tanti le città sono cambiate, gli spazi per le bici aumentati, le ciclabili cominciano ad esserci, i posteggi anche. Insomma forse vale la pena riprovarci.</b>
                    </p>

                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card text-white bg-info mb-3" style="max-width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">12 Rate senza costi aggiuntivi</h5>
                    <p>
                        Da qualche mese non si parla altro che di inflazione crisi energetica. Non si prevedono cali nel costo dei carburanti. Forse è urgente mettersi al riparo dai rincari.
                    </p>
                    <p class="pt-4"><b>
                            Una e-bike usata è una buona scelta: considera che con BikeSquare puoi suddividere il la spesa in 12 rate senza sovrapprezzo. In pratica spenderai meno di quello che ti costa fare il pieno ... e poi, è tutto risparmio.
                        </b>
                    </p>

                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card text-white bg-success mb-3" style="max-width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Try and Buy</h5>
                    <p>
                        Le e-bike usate di BikeSquare sono rientri da noleggio turistico. Hanno pochi mesi di vita, sono magari un po' rigate, hanno qualche difetto estetico, ma la parte meccanica è ottima e la bici e la batteria ti dureranno ancora per anni.
                    </p>
                    <p class="">
                        Noi le cambiamo per garantire ai turisti un mezzo sempre nuovissimo, ma sono perfette per un uso individuale.
                    </p>
                    <p class="pt-4">
                        <b>
                            Tutte le nostre bici si possono provare prima di acquistarle. Le noleggi per un giorno, ti godi una giornata all'aria aperta e se la compri, ti rimborsiamo il noleggio.
                        </b>
                    </p>

                </div>
            </div>

        </div>

    </div>

    <div class="row" id="quanto-costa">
        <div class="col-md-12 pt-4">
            <h2>Sì, ma quanto costa?</h2>
            <div class="card bg-light">
                <div class="card-body row">
                    <div class="col col-md-8 col-12">

                        A questo punto forse vorrai sapere quanto costano le nostre bici elettriche usate.<br>                        
                        <p class="pt-3">Senza impegno, ricevi uno sconto con cui potrai acquistare la tua e-bike usata, dopo averla provata.</p>
                        <p class="text-primary">Per i dipendenti SISAL è riservato un listino speciale che prevede uno sconto di 50€ di una e-bike usata sul sito BikeSquare.</p>
                    </div>
                    <div class="col col-md-4  col-12">
                        <a href="https://forms.gle/iAEKuvgSNinkwepf7" target="_blank" class="btn btn-success btn-lg mt-3">Richiedi buono sconto di 50€</a>                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
    </div>
</template>

<script>
import Util from "@/mixing/util";

export default {
	name: "BiciSisal",
	mixins: [Util],
	data() {
		return {
		};
	},
	computed: {},
	methods: {		
	},
};
</script>

<style>
    html {
        scroll-behavior: smooth;
        font-size: 120%;
    }
</style>
