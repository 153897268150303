<template>
    <div>
        <h2>I Tuoi Dati</h2>
        <p>I seguenti dati sono richiesti dall’operatore del trasporto pubblico per l’emissione di un abbonamento annuale.</p>

        <div class="row">
            <div class="col-md-6">
                <b-form-group>
                    <label for="nome" class="mt-3"><b>*</b>Nome <br /></label>
                    <b-input id="nome" type="text" v-model="form.nome" required placeholder="Nome" data-vv-as="Nome"></b-input>

                    <label for="cognome" class="mt-3"><b>*</b>Cognome <br /></label>
                    <b-input id="cognome" type="text" v-model="form.cognome" required placeholder="Cognome"></b-input>

                    <label for="codice_fiscale" class="mt-3"><b>*</b>Codice fiscale <br /></label>
                    <b-input id="codice_fiscale" type="text" v-model="form.codice_fiscale" required placeholder="Codice fiscale"></b-input>

                    <label for="sesso" class="mt-3"><b>*</b>Genere</label>
                    <b-form-select v-model="form.sesso" :options="l_mm_sesso" required></b-form-select>

                    <label for="residenza" class="mt-3"><b>*</b>Indirizzo di residenza<br /></label>
                    <b-input id="residenza" type="text" v-model="form.residenza" required placeholder="es. Via Plinio, 63"></b-input>

                    <label for="cap_residenza" class="mt-3"><b>*</b>CAP residenza<br /></label>
                    <b-input id="cap_residenza" type="text" v-model="form.cap_residenza" required placeholder="es. 20133"></b-input>

                    <label for="comune_residenza" class="mt-3"><b>*</b>Comune di residenza<br /></label>
                    <b-input id="comune_residenza" type="text" v-model="form.comune_residenza" required placeholder="es. Milano"></b-input>

                    <label for="provincia_residenza" class="mt-3"><b>*</b>Provincia di residenza <em>Inserire solo la sigla.</em><br /></label>
                    <b-input id="provincia_residenza" type="text" v-model="form.provincia_residenza" required placeholder="es. MI"></b-input>
                </b-form-group>
            </div>

            <div class="col-md-6">
                <b-form-group>
                    <label for="data_nascita" class="mt-3"><b>*</b>La tua data di nascita? </label>
                    <b-input id="data_nascita" type="date" v-model="form.data_nascita" required></b-input>

                    <label for="comune_nascita" class="mt-3"><b>*</b>Il tuo comune di nascita?</label>
                    <b-input id="comune_nascita" type="text" v-model="form.comune_nascita" required placeholder="es. Milano"></b-input>

                    <label for="provincia_nascita" class="mt-3"><b>*</b>La tua provincia di nascita? <em>Inserire solo la sigla.</em></label>
                    <b-input id="provincia_nascita" type="text" v-model="form.provincia_nascita" required placeholder="es. MI"></b-input>

                    <label for="cittadinanza" class="mt-3"><b>*</b>Cittadinanza <br /></label>
                    <b-input id="cittadinanza" type="text" v-model="form.cittadinanza" required placeholder="Cittadinanza"></b-input>

                    <label for="documento_ID" class="mt-3"><b>*</b>Scegli il tipo del tuo documento d'identità.</label>
                    <b-form-select v-model="form.documento_ID" :options="tipo_documento" required placeholder="Scegli il tipo del tuo documento d'identità"></b-form-select>

                    <label for="nr_documento_ID" class="mt-3"><b>*</b>Inserisci il numero del tuo documento d'identità.</label>
                    <b-input id="nr_documento_ID" type="text" v-model="form.nr_documento_ID" required></b-input>

                    <label for="e_mail" class="mt-3"><b>*</b>La tua e-mail <br /></label>
                    <b-input id="e_mail" type="email" v-model="form.email" required placeholder="es. mario.rossi@email.com"></b-input>

                    <label for="telefono" class="mt-3"><b>*</b>Il tuo numero di cellulare <br /></label>
                    <b-input id="telefono" type="tel" v-model="form.telefono" required placeholder="Numero cellulare"></b-input>

                    <b-form-group v-if="form.tessera_io_viaggio_mobility == null">                        
                        <label for="numero_tessera" class="mt-3"><b>*</b>Indica il numero della tua tessera di trasporto pubblico. <br />
                            Se non possiedi una tessera scrivi "No"
                        </label>
                        <b-input id="numero_tessera" type="text" v-model="form.numero_tessera" required></b-input>
                    </b-form-group>

                    <label for="fototessera" class="mt-3"><b>*</b>Carica la tua fototessera in formato .jpg</label>
                    <b-form-file id="fototessera" v-model="form.fototessera" plain class="mb-1"></b-form-file>
                    <div>
                        File selezionato:
                        {{ form.fototessera ? form.fototessera.name : "" }}
                    </div>
                </b-form-group>
            </div>
            <p class="mt-3">*Campi obbligatori</p>
        </div>
    </div>
</template>
<script>
import tipo_documento from "../data/tipo-documento";
import { l_mm_sesso } from "@/data/company-survey.config";

export default {
	name: "abbonamentoCard",
	props: { form: Object },
	data() {
		return {
			tipo_documento: tipo_documento,
			l_mm_sesso: l_mm_sesso,
		};
	},
	mounted() {
		const user = this.$cookies.get("user");
		this.form.nome = user.first_name;
		this.form.cognome = user.last_name;
		this.form.codice_fiscale = user.cf;
		this.form.matricola = user.badge_number;
		this.form.telefono = user.mobile;
		this.form.email = user.email;
	},
};
</script>

<style></style>
