import DashboardLayout from "~theme/DashboardLayout.vue";

const routes = [
    {
		path: "/",
		component: DashboardLayout,
		redirect: "/dashboard",
		children: [
			{
				path: "/pendolare",
				name: "Pendolare in Prova",
				// meta: { authorize: ["admin", "pendolare"] },
				meta: {},
				// lazy-loaded
				component: pageComponent("index"),
			},
			{
				path: "/pendolare/riepilogo",
				name: "Le tue Richieste",
				// meta: { authorize: ["admin", "pendolare"] },
				meta: {},
				// lazy-loaded
				component: pageComponent("riepilogo"),
			},
			{
				path: "/pendolare/gtt",
				name: "Pendolare in prova GTT",

				// meta: { authorize: ["admin", "pendolare"] },
				meta: {},
				// lazy-loaded
				component: pageComponent("gtt"),
			},
			{
				path: "/pendolare/trenord",
				name: "Pendolare in prova Trenord",

				// meta: { authorize: ["admin", "pendolare"] },
				meta: {},
				// lazy-loaded
				component: pageComponent("trenord"),
			},
			{
				path: "/pendolare/atm",
				name: "Pendolare in prova ATM",

				// meta: { authorize: ["admin", "pendolare"] },
				meta: {},
				// lazy-loaded
				component: pageComponent("atm"),
			},
			{
				path: "/pendolare/atac",
				name: "Pendolare in prova ATAC",

				// meta: { authorize: ["admin", "pendolare"] },
				meta: {},
				// lazy-loaded
				component: pageComponent("atac"),
			},
            	
        	],
			},
			//Questi elementi usano il template di default
			{
					path: "/pendolare/jointly",
					name: "Single Sign On Jointly",

					meta: {},
					// lazy-loaded
					component: pageComponent("jointly"),
			},
		];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 * **/
function pageComponent(name) {	
	var res;	
		try {
			res = require("@/extra/Testcommuters/pages/" + name + ".vue").default;
		} catch (e) {
			console.log("Errore route");
			res = require("./pages/" + name + ".vue").default;
		}	
	return res;
}

export default routes;