import httpClient from "@/services/httpClient";

var mime = require("mime-types");

class PendolareService {
	saveSportello(form) {
		var myForm = new FormData();
		let keysArray = Object.keys(form);
		for (let k of keysArray) {
			if (k !== "fototessera") {
				myForm.set(k, form[k]);
			}
		}
		//Il file
		myForm.append("fototessera", form.fototessera);

		return httpClient({
			method: "post",
			url: "/testcommuters/testcommuters/add.json",
			data: myForm,
			headers: {
            'Content-Type': 'multipart/form-data'
          	}	
		});
	}

    async getRiepilogoAbbonamenti() {
		return httpClient.get("/testcommuters/testcommuters/riepilogo.json");
	}

	async updateTos() {
		return httpClient.get("/users/update-tos.json");
	}

	getDiscountForUser(user) {
		//TODO: Rendere parametrico
		console.log("USER", user);
		if (user.company_id == 1609){
			return 0.8;
		}
		return 1;
	}
}

export default new PendolareService();
