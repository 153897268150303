const pillars = [
	{
		id: 1,
		name: "Presenza di vincoli",
		description: "",
	},
	{
		id: 2,
		name: "Pianificazione dei trasporti",
		description: "",
	},
	{
		id: 3,
		name: "Mobility Management",
		description: "",
	},
	{
		id: 4,
		name: "Mobility Management",
		description: "",
	},
];

export default pillars;
