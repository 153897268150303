<template>
    <div>
        <b-col>
            <!-- riepilogo dati abbonamento-->
            <b-card border-variant="primary" header="Il tuo abbonamento" header-bg-variant="success" header-text-variant="white" align="center">
                <b-card-text class="mt-2">
                    <p>Abbonamento: {{ abbonamento_selezionato }}</p>
                    <p v-if="sconto">
                        Prezzo pieno: <del>{{ prezzo_pieno.toFixed(2) }}</del> €
                    </p>
                    <p v-else>Prezzo {{ prezzo_pieno.toFixed(2) }}€</p>
                    <div v-if="sconto">
                        Scontato<br />
                        <span class="text-muted">(grazie al cofinanziamento aziendale)</span><br />
                        <h4>{{ prezzo_scontato.toFixed(2) }} €</h4>
                    </div>
                </b-card-text>
            </b-card>
            <!-- end of riepilogo dati abbonamento-->
        </b-col>
    </div>
</template>
<script>
export default {
	name: "abbonamentoCard",
	props: {
		abbonamento_selezionato: String,
		prezzo_pieno: Number,
		prezzo_scontato: Number,
	},
	computed: {
		sconto: function () {
			return parseFloat(this.prezzo_pieno) - parseFloat(this.prezzo_scontato) != 0;
		},
	},
};
</script>
<style></style>
