<template>
    <div>
        <b-row>
            <b-breadcrumb class="col-md-10">
                <b-breadcrumb-item href="/">
                    <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                    Home
                </b-breadcrumb-item>
                <b-breadcrumb-item href="/abbonamenti">Scelta Operatore</b-breadcrumb-item>
                <b-breadcrumb-item active> Trenord </b-breadcrumb-item>
            </b-breadcrumb>
            <b-col class="col-md-2">
                <b-img src="/abbonamenti/trenord.jpg" alt="Trenord" class="float-right" style="max-height: 50px; align: middle"></b-img>
            </b-col>
        </b-row>

        <b-tabs content-class="mt-3" v-model="activeTab">
            <!-- step-2: selezione / calcolo dell'abbonamento-->
            <b-tab title="Tratta" id="step-2" value="step-2" card>
                <h2>Il Tuo Abbonamento</h2>
                <b-form ref="form-0">
                    <b-row>
                        <b-col cols="6">
                            <p></p>
                            <p>
                                <b-icon icon="question-circle-fill" variant="success"></b-icon>
                                Consulta le informazioni sugli
                                <a
                                    href="https://trenord-europe-trenord-endpoint-prd.azureedge.net/fileadmin/contenuti/TRENORD/4-Info_e_assistenza/Informazioni_utili/Condizioni_di_trasporto/CONDIZIONI__DI_TRASPORTO_DOCUMENTI/AvvisoA3Trenord_2020_167_REV2__tariffario.pdf"
                                    target="blank">abbonamenti annuali Trenord</a>
                            </p>
                        </b-col>
                    </b-row>

                    <b-row>
                        <!--si indica l'origine e la destinazione del tragitto senza calcollare l'abbonamento -->
                        <div class="col-md-4 border-right">
                            <h4 class="text-center">Indicare l'origine e la destinazione del tuo tragitto</h4>
                            <b-form-group>
                                <label for="origine"><b>*</b>Da quale città parti?</label>
                                <b-input
                                    v-model="form.origine"
                                    :serializer="(s) => s.name"
                                    :data="comuni"
                                    placeholder="Inserici il comune da cui parti"
                                    id="origine"
                                    class="mb-2"
                                    required>
                                </b-input>
                                <label for="destinazione"><b>*</b>Qual è la tua città di destinazione?</label>
                                <b-input
                                    v-model="form.destinazione"
                                    :serializer="(s) => s.name"
                                    :data="comuni"
                                    placeholder="Inserici il comune della tua destinazione"
                                    id="destinazione"
                                    class="mb-2"
                                    required>
                                </b-input>
                            </b-form-group>
                            <p class="mt-3">*Campi obbligatori</p>
                        </div>
                        <!--fine indicazione origine destinazione -->
                        <!-- Scelta abbonamento -->
                        <div class="col-md-4">
                            <b-overlay :show="loading" rounded="sm">
                                <h4 class="text-center">Scegli il tuo abbonamento</h4>
                                <b-form-select v-model="form.abbonamento_selezionato" required>
                                    <b-select-option v-for="(t, index) in tariffe" :key="index" :value="index"> {{ index }} - {{ t.price }} € </b-select-option>
                                </b-form-select>
                                <br /><br />

                                <p>
                                    <b>Sei già in possesso di una tessera Mobility valida?</b>
                                </p>
                                <b-form-group required>
                                    <b-form-radio v-model="form.tessera_tpl" name="tessera_tpl" :value="1">Sì</b-form-radio>
                                    <!--:value in questo modo il programma capisce lo "0" come valore e non parola-->
                                    <b-form-radio v-model="form.tessera_tpl" name="tessera_tpl" :value="0">No</b-form-radio>
                                </b-form-group>

                                <b-form-group v-if="form.tessera_tpl == 1" label="Indica la stazione in cui ricarichi">
                                    <b-form-select v-model="form.stazione_ricarica_id" :options="stazioni_ricarica" required @change="aggiornaNomeStazioneRicarica()">
                                    </b-form-select>
                                </b-form-group>
                            </b-overlay>
                        </div>
                        <!-- Fine scelta abbonamento -->
                    </b-row>

                    <!-- end of seleziona abbonamento dall'elenco-->
                    <div class="row mt-4">
                        <!--qui viene visualizzato l'output del calcolo dell'abbonamento-->                        
                        <div class="col-md-4">
                            <abbonamento-card :abbonamento_selezionato="form.abbonamento_selezionato" :prezzo_pieno="prezzo_pieno" :prezzo_scontato="prezzo_scontato">
                            </abbonamento-card>
                        </div>
                    </div>
                </b-form>
                <b-card-footer>
                    <b-button @click="nextTab()" variant="primary" class="float-right">Avanti</b-button>
                </b-card-footer>
                <!-- end of calcola abbonamento-->
            </b-tab>
            <!-- end of step-2:selezione / calcolo dell'abbonamento-->

            <!-- step-4: dati aggiuntivi -->
            <b-tab title="I tuoi dati" id="step-3" value="step-3">
                <b-form ref="form-1">
                    <abbonamento-user :form="form"></abbonamento-user>
                </b-form>
                <b-card-footer>
                    <b-button variant="primary" class="float-right" @click="nextTab()">Avanti</b-button>
                </b-card-footer>
            </b-tab>
            <!-- end of step-4 nuovo abbonamento -->

            <!-- step-5: riepilogo e submit-->
            <b-tab title="Riepilogo" id="step-4" value="step-4">
                <b-form ref="form-2">
                    <abbonamento-riepilogo :form="form" :prezzo_pieno="prezzo_pieno" :prezzo_scontato="prezzo_scontato"></abbonamento-riepilogo>
                </b-form>
                <b-card-footer>
                    <b-button variant="primary" class="mr-2 float-right" @click.prevent="submit()">
                        <div v-if="loading">
                            <b-spinner small></b-spinner>
                            <span class="sr-only">Sto salvando...</span>
                        </div>
                        <div v-else>
                            <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
                            Invia la richiesta (acquista)
                        </div>
                    </b-button>
                </b-card-footer>
            </b-tab>
            <!-- end of step-5: riepilogo e submit-->
        </b-tabs>
        <!-- end of acquisto abbonamento-->
    </div>
</template>

<script>
import Util from "@/mixing/util";
import AbbonamentoCard from "../components/AbbonamentoCard";
import AbbonamentoUser from "../components/AbbonamentoUser";
import AbbonamentoRiepilogo from "../components/AbbonamentoRiepilogo";
import axios from "axios";
import PendolareService from "../pendolare.service";
import validitaTrenord from "../data/validita-trenord";
import tariffe_trenord from "../data/tariffe-trenord";
import tipo_documento from "../data/tipo-documento";
import comuni from "../data/comuni-mi";
import mesi from "../data/mesi";
import stazioni_ricarica from "../data/stazioni-ricarica-trenord";

export default {
	name: "Abbonamenti",
	components: {
		AbbonamentoCard,
		AbbonamentoUser,
		AbbonamentoRiepilogo,
	},
	mixins: [Util],
	data() {
		return {
			activeTab: 0,
			loading: false,
			validita: validitaTrenord,
			tipo_documento: tipo_documento,
			abbonamento_calcolato: null,
			tariffe: tariffe_trenord,
			prezzo_tessera: 5,
			perc_carico_utente: 1,
			stazioni_ricarica: stazioni_ricarica,
			comuni: comuni,

			form: {
				origine: null, //queste variabili devono comparire nel v-model del form
				destinazione: null, //aggiungi nome_campo:valore, per ogni campo che crei
				mese_validita: null,
				abbonamento_selezionato: null,
				nome: null,
				cognome: null,
				codice_fiscale: null,
				cittadinanza: null,
				fototessera: null,
				comune_nascita: null,
				provincia_nascita: null,
				numero_tessera: null,
				documento_ID: null,
				nr_documento_ID: null,
				residenza: null,
				email: null,
				telefono: null,
				matricola: null,
				privacy: false,
				tessera_tpl: null,
				operatore: "trenord",
				prezzo_pieno: 0,
				prezzo_scontato: 0,
				sesso: "",
			},
		};
	},
	created() {
		this.ordina_mesi();
		this.form.mese_validita = this.prossimo_to_YMD;
		this.perc_carico_utente = PendolareService.getDiscountForUser(this.getUser);
	},
	computed: {
		prezzo_abbonamento: function () {
			if (typeof this.tariffe[this.form.abbonamento_selezionato] === "undefined") {
				return 0;
			} else {
				return this.tariffe[this.form.abbonamento_selezionato].price;
			}
		},
		prezzo_pieno: function () {
			return parseInt(this.prezzo_abbonamento);
		},
		prezzo_scontato: function () {
			return parseFloat(this.prezzo_pieno * this.perc_carico_utente);
		},
		mesi_futuri: function () {
			var d = new Date();
			var m = d.getMonth();
			var y = d.getYear() + 1900;
			var result = [];
			for (let i = 0; i < 6; i++) {
				if (m + i == 12) {
					y++;
				}
				result.push(mesi[(m + i) % 12] + " " + y);
			}
			return result;
		},
		scegli_prossimo: function () {
			let d = new Date();
			let m = d.getMonth() + 1 ;
			let g = d.getDate();
			
			let x = this.validita.find((x) => {
				let n = m+1;
				if (n>12) {
					n = 1;
				}
				return (m == x.ordina_entro_il_mm && g <= x.ordina_entro_il_gg) || ( n  == x.ordina_entro_il_mm);
			});
			
			return x;
		},
		prossimo_to_YMD: function () {
			return `${this.num_to_anno(this.scegli_prossimo.validita_abbonamento)}-${this.scegli_prossimo.validita_abbonamento.toString().padStart(2, "0")}-01`;
		},
	},
	methods: {
		submit() {
			this.loading = true;
			this.form.prezzo_pieno = this.prezzo_pieno;
			this.form.prezzo_scontato = this.prezzo_scontato;
			if (this.form.stato_privacy != "accettato") {
				alert("E' necessario accettare la privacy policy per procedere");
				this.loading = false;
				return;
			}

			PendolareService.saveSportello(this.form).then(
				() => {
					this.$bvModal.msgBoxOk("Abbiamo ricevuto la tua richiesta, puoi controllare l'avanzamento nella pagina del riepilogo").then(() => {
						this.loading = false;
						this.$router.push("riepilogo");
					});
				},
				(error) => {
					this.showError(error);
					this.loading = false;
				}
			);
			this.loading = false;
		},
		aggiornaNomeStazioneRicarica() {
			this.form.stazione_ricarica_nome = stazioni_ricarica.find((x) => x.value == this.form.stazione_ricarica_id).text;
		},
		onReset(evt) {
			evt.preventDefault();
			// Reset our form values
			//this.form.email = ''
			//this.form.name = ''
		},
		nextTab() {
			let f = "form-" + this.activeTab;
			if (this.$refs[f].checkValidity()) {
				this.activeTab++;
			} else {
				this.$refs[f].reportValidity();
			}
		},
		notYet() {
			this.$bvModal.msgBoxOk("Questo servizio non è ancora integrato nella piattaforma. Ci stiamo lavorando");
		},
		num_to_mese(x) {
			return mesi[x];
		},
		num_to_anno(x) {
			var d = new Date();
			var m = d.getMonth() + 1;
			var y = d.getYear() + 1900;
			//Se ho già superato il mese da scrivere lo considero nel prossimo anno
			if (m > x) {
				y++;
			}
			return y;
		},
		ordina_mesi() {
			var d = new Date();
			var m = d.getMonth() + 1;

			this.validita.map((x) => {
				if (x.ordina_entro_il_mm < m) {
					x.order = x.ordina_entro_il_mm + 12 - m;
				} else {
					x.order = x.ordina_entro_il_mm - m;
				}

				return x;
			});
			this.validita.sort(function (a, b) {
				return a.order - b.order;
			});
		},
		calcola_tariffa() {
			let origine = this.comuni.find((x) => x.name == this.form.origine);
			let destinazione = this.comuni.find((x) => x.name == this.form.destinazione);

			if (this.form.origine != null && this.form.destinazione != null) {
				if (this.form.origine == "Milano" && this.form.destinazione == "Milano") {
					this.form.abbonamento_selezionato = "1urb_UrbanoMilano";
					return;
				} else if (this.form.origine == this.form.destinazione) {
					this.form.abbonamento_selezionato = origine.zona + "-" + destinazione.zona;
				} else {
					//Sorgente http://www.agenziatpl.it/calcola-tariffa-stibm/www/
					let url = "https://europe-west1-stibm-1561387563826.cloudfunctions.net/";
					this.loading = true;

					var datetime = new Date().toISOString();
					var urlRequest =
                        url + "get_directions_data?origin=" + encodeURI(origine.name) + "&destination=" + encodeURI(destinazione.name) + "&departure_time=" + encodeURI(datetime);
					urlRequest = urlRequest.replace("+", "PLUS");

					axios.get(urlRequest).then(
						(result) => {
							this.form.abbonamento_selezionato = result.data[0].fare.zone_id;
							this.loading = false;
						},
						(error) => {
							this.showError(error);
							this.loading = false;
						}
					);

					return;
				}
			}
		},
	},
};
</script>

<style>
.card-img-top {
    padding: 1em;
}
</style>
