import DashboardLayout from "~theme/DashboardLayout.vue";

const routes = [
    {
		path: "/",
		component: DashboardLayout,
		redirect: "/dashboard",
		children: [
			{
				path: "/mobility-label/:office_id",
				name: "Mobility Label",

				meta: {
					authorize: ["admin", "moma", "moma_area"],
				},
				// lazy-loaded
				component: pageComponent("view"),
			},
			{
				path: "/mobility-label/edit/:office_id",
				name: "Questionario Mobility Label",

				meta: { authorize: ["admin", "moma", "moma_area"] },
				// lazy-loaded
				component: pageComponent("edit"),
			},
		]
	}
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 * **/
function pageComponent(name) {	
	var res;	
		try {
			res = require("@/extra/MobilityLabel/pages/" + name + ".vue").default;
		} catch (e) {
			console.log("Errore route");
			res = require("./pages/" + name + ".vue").default;
		}	
	return res;
}

export default routes;