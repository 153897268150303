import emissioniA from "@/models/emissioniA";
import emissioniB from "@/models/emissioniB";

const measures = [
	{
		id: 1,
		slug: "vincoli-ambientali",
		pillar_id: 1,
		name: "Vincoli Ambientali",
		description: "",
		img: "",
		target: "pascal", //azienda o scuola
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "vincoli", label: "Presenza Vincoli Ambientali", field_type: "radio" },
			{ key: "superficie", label: "Sup. interessata (mq)", field_type: "number" },
			{ key: "note", label: "Note", field_type: "text" },
		],
		indicator: (serie) => null,
	},
	{
		id: 2,
		slug: "vincoli-paesaggistici",
		pillar_id: 1,
		name: "Vincoli Paesaggistici",
		description: "",
		img: "",
		target: "pascal", //azienda o scuola
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "vincoli", label: "Presenza Vincoli Paesaggistici", field_type: "radio" },
			{ key: "superficie", label: "Sup. interessata (mq)", field_type: "number" },
			{ key: "note", label: "Note", field_type: "text" },
		],
		indicator: (serie) => null,
	},
	{
		id: 3,
		slug: "vincoli-archeologici",
		pillar_id: 1,
		name: "Vincoli Archeologici",
		description: "",
		img: "",
		target: "pascal", //azienda o scuola
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "vincoli", label: "Presenza Vincoli Archeologici", field_type: "radio" },
			{ key: "superficie", label: "Sup. interessata (mq)", field_type: "number" },
			{ key: "note", label: "Note", field_type: "text" },
		],
		indicator: (serie) => null,
	},
	{
		id: 4,
		slug: "vincoli-idrogeologici",
		pillar_id: 1,
		name: "Vincoli Irdogeologici",
		description: "",
		img: "",
		target: "pascal", //azienda o scuola
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "vincoli", label: "Presenza Vincoli Idrogeologici", field_type: "radio" },
			{ key: "superficie", label: "Sup. interessata (mq)", field_type: "number" },
			{ key: "note", label: "Note", field_type: "text" },
		],
		indicator: (serie) => null,
	},
	{
		id: 5,
		slug: "vincoli-altro",
		pillar_id: 1,
		name: "Altri Vincoli",
		description: "",
		img: "",
		target: "pascal", //azienda o scuola
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "vincoli", label: "Presenza Altri Vincoli", field_type: "radio" },
			{ key: "altro", label: "Specificare", field_type: "text" },
			{ key: "superficie", label: "Sup. interessata (mq)", field_type: "number" },
			{ key: "note", label: "Note", field_type: "text" },
		],
		indicator: (serie) => null,
	},
	/**** PIANIFICAZIONE TRASPORTI */
	{
		id: 6,
		slug: "put",
		pillar_id: 2,
		name: "Piano Urbano del Traffico (PUT)",
		description: "Riportare gli estremi deli atti amministrativi di approvazione",
		img: "/measures/rete-tpl.jpg",
		target: "pascal", //azienda o scuola
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "Yes", label: "", field_type: "radio" },
			{ key: "Num", label: "Num.", field_type: "number" },
			{ key: "dt", label: "Data", field_type: "date" },
		],
		indicator: (serie) => null,
	},

	{
		id: 7,
		slug: "pum",
		pillar_id: 2,
		name: "Piano Urbano della Mobilità (PUM)",
		description: "Riportare gli estremi deli atti amministrativi di approvazione",
		img: "/measures/tpl.jpg",
		target: "pascal", //azienda o scuola
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "Yes", label: "", field_type: "radio" },
			{ key: "Num", label: "Num.", field_type: "number" },
			{ key: "dt", label: "Data", field_type: "date" },
		],
		indicator: (serie) => null,
	},

	{
		id: 8,
		slug: "pums",
		pillar_id: 2,
		name: "Piano Urbano della Mobilità Sostenibile (PUMS)",
		description: "Riportare gli estremi deli atti amministrativi di approvazione",
		img: "/measures/car-free-street.jpg",
		target: "pascal", //azienda o scuola
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "Yes", label: "", field_type: "radio" },
			{ key: "Num", label: "Num.", field_type: "number" },
			{ key: "dt", label: "Data", field_type: "date" },
		],
		indicator: (serie) => null,
	},

	{
		id: 9,
		slug: "paes",
		pillar_id: 2,
		name: "Piano d'Azione per l'energia sostenibile (PAES)",
		description: "Riportare gli estremi deli atti amministrativi di approvazione",
		img: "/measures/cortili-car-free.jpg",
		target: "pascal", //azienda o scuola
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "Yes", label: "", field_type: "radio" },
			{ key: "Num", label: "Num.", field_type: "number" },
			{ key: "dt", label: "Data", field_type: "date" },
		],
		indicator: (serie) => null,
	},

	{
		id: 10,
		slug: "q-aria",
		pillar_id: 2,
		name: "Accordi di programma/Accordi territoriali per il miglioramento della qualità dell'aria",
		description: "Riportare gli estremi deli atti amministrativi di approvazione",
		img: "",
		target: "pascal", //azienda o scuola
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "Yes", label: "", field_type: "radio" },
			{ key: "Num", label: "Num.", field_type: "number" },
			{ key: "dt", label: "Data", field_type: "date" },
		],
		indicator: (serie) => null,
	},

	{
		id: 11,
		slug: "circolazione",
		pillar_id: 2,
		name: "Misure di regolamentazione della circolazione",
		description: "Riportare gli estremi deli atti amministrativi di approvazione",
		img: "/measures/multimodalita.jpg",
		target: "pascal", //azienda o scuola
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "Yes", label: "", field_type: "radio" },
			{ key: "Num", label: "Num.", field_type: "number" },
			{ key: "dt", label: "Data", field_type: "date" },
		],
		indicator: (serie) => null,
	},

	{
		id: 12,
		slug: "progetto-eu",
		pillar_id: 2,
		name: "Progetto EU",
		description: "Riportare gli estremi deli atti amministrativi di approvazione",
		img: "",
		target: "pascal", //azienda o scuola
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "Yes", label: "", field_type: "radio" },
			{ key: "Num", label: "Num.", field_type: "number" },
			{ key: "dt", label: "Data", field_type: "date" },
		],
		indicator: (serie) => null,
	},

	/**** Mobility Management */
	{
		id: 13,
		slug: "moma_area",
		pillar_id: 3,
		name: "Mobility Management d'Area",
		description: "Riportare gli estremi deli atti amministrativi di nomina",
		img: "/measures/accessibilita.jpg",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "Yes", label: "", field_type: "radio" },
			{ key: "Num", label: "Num.", field_type: "number" },
			{ key: "dt", label: "Data", field_type: "date" },
		],
		indicator: (serie) => null,
	},

	{
		id: 14,
		slug: "moma-azienda",
		pillar_id: 3,
		name: "Mobility Manager Aziendali",
		description: "",
		img: "/measures/posteggi-bici.jpg",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "NMM", label: "Numero Mobility Manager", field_type: "number" },
			{ key: "Yes", label: "", field_type: "radio" },
			{ key: "US", label: "Utenti serviti", field_type: "number" },
		],
		indicator: (serie) => null,
	},

	{
		id: 15,
		slug: "moma-scuola",
		pillar_id: 3,
		name: "Mobility Manager Scolastici",
		description: "",
		img: "/measures/spogliatoio.jpg",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "NMM", label: "Numero Mobility Manager", field_type: "number" },
			{ key: "Yes", label: "", field_type: "radio" },
			{ key: "SS", label: "Studenti serviti", field_type: "number" },
		],
		indicator: (serie) => null,
	},

	/**** Azioni */
	{
		id: 16,
		slug: "pedibus",
		pillar_id: 4,
		name: "Percorsi Pedibus",
		description:
            "Organizzare e promuovere il PediBus per lo spostamento casa-scuola. Con il PediBus i bambini vanno e tronano dalla scuola             insieme, camminando lungo un percorso prestabilito e accompagnati da adulti volontari (es. genitori, nonni, insegnanti).",
		img: "/measures/pedibus.jpg",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "nump", label: "Numero Percorsi Previsti", field_type: "number" },
			{ key: "ut", label: "Numero Medio di alunni coinvolti giornalmente", field_type: "number" },
			{ key: "l", label: "Lunghezza totale dei percorsi", field_type: "number" },
			{ key: "gg", label: "Giorni di servizio (stima su base annua)", field_type: "number" },
			{ key: "costo", label: "Costo previsto (€/anno)", field_type: "number" },
		],
		indicator: (serie) => {
			return new emissioniA(
				serie.gg, //giorni di attività
				serie.ut, //numero utenti sottratti all'auto
				parseFloat(serie.l / serie.nump) //riduzione km auto
			);
		},
	},

	{
		id: 17,
		slug: "car-sharing",
		pillar_id: 4,
		name: "Car Sharing",
		description: "",
		img: "/measures/posteggi-auto.jpg",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "na", label: "Numero auto", field_type: "number" },
			{ key: "al", label: "Alimentazione", field_type: "text" },
			{ key: "kp", label: "Km percorsi (stima su base annua per singolo veicolo)", field_type: "number" },
			{ key: "cu", label: "Costo unitario (€) del veicolo attrezzato per il servizio", field_type: "number" },
		],
		indicator: (serie) =>
			new emissioniB(
				365, //giorni di attività
				parseFloat(serie.kp / 365), //riduzione_km_auto
				8, //utenti_sharing
				8 * parseFloat(serie.kp / 365), //percorrenza_media_evitata
				serie.na * 8 //noleggi_gg - costanti fornite dal ministero (call elena 4 giugno 2021)
			),
	},

	{
		id: 18,
		slug: "car-pooling",
		pillar_id: 4,
		name: "Car pooling",
		description: "",
		img: "/measures/posteggio-carpooling.jpg",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [{ key: "nu", label: "Numero utenti(stima su base annua)", field_type: "number" }],
		indicator: (serie) =>
			new emissioniB(
				220, //giorni di attività
				serie.nu * 25, //riduzione_km_auto
				serie.nu, //utenti_sharing
				25, //percorrenza_media_evitata
				serie.nu / 4 //noleggi_gg
			),
	},
	{
		id: 19,
		slug: "bike-sharing",
		pillar_id: 4,
		name: "Bike Sharing",
		description: "",
		img: "/measures/bike-sharing.jpg",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "nc", label: "Numero biciclette", field_type: "number" },
			{ key: "tp", label: "Tipologia", field_type: "text" },
			{ key: "kp", label: "Km percorsi(stima su base annua per singola bici)", field_type: "number" },
			{ key: "cub", label: "Costo unitario bici(€)", field_type: "number" },
		],
		indicator: (serie) => {
			return new emissioniA(
				365, //giorni di attività
				serie.nc * 2.8, //numero utenti sottratti all'auto
				3.24 //riduzione km auto (da calcolo complicatissimo di elena pedon, call 4 giugno 2021)
			);
		},
	},
	{
		id: 20,
		slug: "velostazioni",
		pillar_id: 4,
		name: "Velostazioni",
		description: "",
		img: "/measures/bici-aziendali.jpg",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "nv", label: "Numero velostazioni", field_type: "number" },
			{ key: "nspv", label: "Numero stalli per velostazione", field_type: "number" },
			{ key: "tps", label: "Tipologia stalli", field_type: "text" },
			{ key: "cu/s", label: "Costo unitario/stallo(€)", field_type: "number" },
		],
		indicator: (serie) => {
			return new emissioniA(
				365, //giorni di attività
				serie.nspv * serie.nv * 2.8, //numero utenti sottratti all'auto
				3.24 //riduzione km auto (da calcolo complicatissimo di elena pedon, call 4 giugno 2021)
			);
		},
	},
	//TODO: Aggiungere una misura bikebox e rastrelliere (altro),
	// dove riduzione km auto => 1.5 (togliere da Altri servizi di mobilità collettiva e condivisa)

	{
		id: 21,
		slug: "scooter-sharing",
		pillar_id: 4,
		name: "Scooter Sharing",
		description: "",
		img: "",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "ns", label: "Numero scooter", field_type: "number" },
			{ key: "al", label: "Alimentazione", field_type: "text" },
			{ key: "kp", label: "Km percorsi(stima su base annua per singola scooter)", field_type: "number" },
			{ key: "cus", label: "Costo unitario scooter(€)", field_type: "number" },
		],
		indicator: (serie) => null,
	},
	{
		id: 22,
		slug: "infomobilita",
		pillar_id: 4,
		name: "Infomobilità",
		description: "",
		img: "/measures/informazioni.jpg",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "npi", label: "Numero paline informative", field_type: "number" },
			{ key: "cu", label: "Costo unitario(€)", field_type: "number" },
			{ key: "nas", label: "Numero applicazioni sviluppate", field_type: "number" },
			{ key: "npmv", label: "Numero pannelli a messaggio variabile", field_type: "number" },
			{ key: "costo", label: "Costo unitario(€)", field_type: "number" },
		],
		indicator: (serie) => null,
	},
	{
		id: 23,
		slug: "mobilita-condivisa",
		pillar_id: 4,
		name: "Altri servizi di mobilità condivisa",
		description: "",
		img: "",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "ns", label: "Numero servizi", field_type: "number" },
			{ key: "ds", label: "Descrizione", field_type: "text" },
			{ key: "nu", label: "Numero utenti(stima su base annua)", field_type: "number" },
			{ key: "costop", label: "Costo previsto(€)", field_type: "number" },
		],
		indicator: (serie) => null,
	},
	{
		id: 24,
		slug: "piste-ciclabili",
		pillar_id: 4,
		name: "Piste Ciclabili",
		description: "",
		img: "/measures/segnaletica-bici.jpg",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "tp", label: "Tipologia", field_type: "text" },
			{ key: "lt", label: "Lunghezza totale(Km)", field_type: "number" },
			{ key: "up", label: "Utenti potenziali(num, medio giornaliero)", field_type: "number" },
			{ key: "costo", label: "Costo(€/Km)", field_type: "number" },
		],
		indicator: (serie) => {
			return new emissioniA(
				365, //giorni di attività
				serie.up, //numero utenti sottratti all'auto
				serie.up / 1,
				2 * 3 //riduzione km auto (le costanti sono definite da Elena 4 giu 2021)
			);
		},
	},
	{
		id: 25,
		slug: "percorsi-pedonali",
		pillar_id: 4,
		name: "Percorsi Pedonali",
		description: "",
		img: "/measures/accessibilita.jpg",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "tp", label: "Tipologia", field_type: "text" },
			{ key: "lt", label: "Lunghezza totale(Km)", field_type: "number" },
			{ key: "up", label: "Utenti potenziali(num, medio giornaliero)", field_type: "number" },
			{ key: "costo", label: "Costo(€/Km)", field_type: "number" },
		],
		indicator: (serie) => {
			return new emissioniA(
				200, //giorni di attività
				serie.up, //numero utenti sottratti all'auto
				(serie.up / 1.2) * 1 //riduzione km auto (da file CALCOLI_EMISSIONI.xls > IIgruppo > riga 44)
			);
		},
	},
	{
		id: 26,
		slug: "zone-30",
		pillar_id: 4,
		name: "Zone 30",
		description: "",
		img: "",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "su", label: "Superficie(Kmq)", field_type: "number" },
			{ key: "lsi", label: "Lunghezza strade interessate(Km)", field_type: "number" },
			{ key: "ui", label: "Utenti interessati", field_type: "number" },
		],
		indicator: (serie) => {
			return new emissioniA(
				365, //giorni di attività
				serie.ui, //numero utenti sottratti all'auto
				(serie.ui / 1.2) * 0.75 //riduzione km auto (da file CALCOLI_EMISSIONI.xls > IIgruppo > riga 48)
			);
		},
	},
	{
		id: 27,
		slug: "uscite-didattiche",
		pillar_id: 4,
		name: "Uscite Didattiche",
		description: "",
		img: "",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "nudp", label: "Numero uscite didattiche programmata(stima su base annua)", field_type: "number" },
			{ key: "nup", label: "Numero utenti potenziali(stima su base annua)", field_type: "number" },
		],
		indicator: (serie) => {
			return new emissioniA(
				serie.nudp, //giorni di attività
				serie.nup, //numero utenti sottratti all'auto
				(serie.nup / 1.2) * 10 //riduzione km auto (da file CALCOLI_EMISSIONI.xls > III_VII gruppo > riga 6)
			);
		},
	},
	{
		id: 28,
		slug: "spostamenti-lavoro",
		pillar_id: 4,
		name: "Spostamenti per lavoro",
		description: "",
		img: "",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "nsp", label: "Numero spostamenti programmati(stima su base annua)", field_type: "number" },
			{ key: "nup", label: "Numero utenti potenziali(stima su base annua)", field_type: "number" },
		],
		indicator: (serie) => {
			return new emissioniA(
				220, //giorni di attività
				serie.nup, //numero utenti sottratti all'auto
				(serie.nup / 1.2) * 5 //riduzione km auto (da file CALCOLI_EMISSIONI.xls > III_VII gruppo > riga 10)
			);
		},
	},
	{
		id: 29,
		slug: "formazione",
		pillar_id: 4,
		name: "Formazione",
		description: "",
		img: "",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "ncp", label: "Numero corsi programmati(stima su base annua)", field_type: "number" },
			{ key: "nup", label: "Numero allievi/partecipanti potenziali(stima su base annua)", field_type: "number" },
		],
		indicator: (serie) => {
			return new emissioniA(
				200, //giorni di attività
				serie.nup, //numero utenti sottratti all'auto
				(serie.nup / 1.2) * 1 //riduzione km auto (da file CALCOLI_EMISSIONI.xls > III_VII gruppo > riga 10)
			);
		},
	},
	{
		id: 30,
		slug: "riduzione-traffico",
		pillar_id: 4,
		name: "Programmi di riduzione del Traffico",
		description: "",
		img: "",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "nv", label: "Numero varchi", field_type: "number" },
			{ key: "nns", label: "Numero nuovi semafori", field_type: "number" },
			{ key: "nna", label: "Numero nuovi attraversamenti", field_type: "number" },
			{ key: "sapi", label: "Superficie area pedonale interessata(kmq)", field_type: "number" },
		],
		indicator: (serie) => {
			return new emissioniA(
				365, //giorni di attività
				250, //numero utenti sottratti all'auto
				(250 / 1.2) * 0.75 //riduzione km auto (da file CALCOLI_EMISSIONI.xls > III_VII gruppo > riga 10)
			);
		},
	},
	{
		id: 31,
		slug: "buoni-mobilita",
		pillar_id: 4,
		name: "Buoni Mobilità",
		description: "",
		img: "",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "nba", label: "Numero buoni/anno", field_type: "number" },
			{ key: "nup", label: "Numero beneficiari/anno", field_type: "number" },
			{ key: "rkm", label: "Riduzione Km/anno con auto privata", field_type: "number" },
		],
		indicator: (serie) => {
			return new emissioniA(
				95, //giorni di attività
				serie.nup, //numero utenti sottratti all'auto
				(serie.nup / 1.2) * serie.rkm //riduzione km auto (da file CALCOLI_EMISSIONI.xls > III_VII gruppo > riga 34)
			);
		},
	},
	{
		id: 32,
		slug: "altro",
		pillar_id: 4,
		name: "Altro",
		description: "",
		img: "",
		target: "pascal",
		type: "1", //1 = misura, 2 = servizio
		labels: [
			{ key: "sp", label: "Specificare", field_type: "text" },
			{ key: "c/um", label: "Costo/unita di misura", field_type: "number" },
		],
		indicator: (serie) => null,
	},
];

export default measures;
