const tariffe_stibm = {
	"1urb_UrbanoMilano": {
		ordinario: {
			ord: "2,00",
			giorn: "7,00",
			tregiorn: "12,00",
		},
		abbordinario: {
			sett: "17,00",
			mensile: "39,00",
			annuale: "330,00",
		},
		abbagevolato: {
			mensile: "37,50",
			annuale: "345,00",
			annualeisee: "69,00",
		},
		abbconvenzione: {
			annuale: "319,00",
			tariff_id: "74200",
		},
		abb26: {
			annuale: "319,00",
			tariff_id: "74200",
		},
		abb65: {
			annuale: "319,00",
			tariff_id: "74200",
		},
	},
	"Mi1-Mi3": {
		ordinario: {
			ord: "2,00",
			giorn: "7,00",
			tregiorn: "12,00",
		},
		abbordinario: {
			sett: "17,00",
			mensile: "50,00",
			annuale: "460,00",
		},
		abbagevolato: {
			mensile: "37,50",
			annuale: "345,00",
			annualeisee: "69,00",
		},
		abbconvenzione: {
			annuale: "460,00",
			tariff_id: "73628",
		},
		abb26: {
			annuale: "345,00",
			tariff_id: "78910",
		},
		abb65: {
			annuale: "345,00",
			tariff_id: "79600",
		},
	},
	"Mi1-Mi4": {
		ordinario: {
			ord: "2,40",
			giorn: "8,40",
			tregiorn: "14,50",
		},
		abbordinario: {
			sett: "20,50",
			mensile: "60,00",
			annuale: "552,00",
		},
		abbagevolato: {
			mensile: "45,00",
			annuale: "414,00",
			annualeisee: "83,00",
		},
		abbconvenzione: {
			annuale: "552,00",
			tariff_id: "78800",
		},
		abb26: {
			annuale: "414,00",
			tariff_id: "78911",
		},
		abb65: {
			annuale: "414,00",
			tariff_id: "79601",
		},
	},
	"Mi1-Mi5": {
		ordinario: {
			ord: "2,80",
			giorn: "9,80",
			tregiorn: "17,00",
		},
		abbordinario: {
			sett: "24,00",
			mensile: "70,00",
			annuale: "644,00",
		},
		abbagevolato: {
			mensile: "53,00",
			annuale: "483,00",
			annualeisee: "97,00",
		},
		abbconvenzione: {
			annuale: "644,00",
			tariff_id: "78801",
		},
		abb26: {
			annuale: "483,00",
			tariff_id: "78912",
		},
		abb65: {
			annuale: "483,00",
			tariff_id: "79602",
		},
	},
	"Mi1-Mi6": {
		ordinario: {
			ord: "3,20",
			giorn: "11,00",
			tregiorn: "19,00",
		},
		abbordinario: {
			sett: "27,00",
			mensile: "77,00",
			annuale: "682,00",
		},
		abbagevolato: {
			mensile: "58,00",
			annuale: "512,00",
			annualeisee: "102,00",
		},
		abbconvenzione: {
			annuale: "682,00",
			tariff_id: "78802",
		},
		abb26: {
			annuale: "512,00",
			tariff_id: "78913",
		},
		abb65: {
			annuale: "512,00",
			tariff_id: "79603",
		},
	},
	"Mi1-Mi7": {
		ordinario: {
			ord: "3,60",
			giorn: "12,50",
			tregiorn: "21,50",
		},
		abbordinario: {
			sett: "30,50",
			mensile: "82,00",
			annuale: "712,00",
		},
		abbagevolato: {
			mensile: "62,00",
			annuale: "534,00",
			annualeisee: "107,00",
		},
		abbconvenzione: {
			annuale: "712,00",
			tariff_id: "78803",
		},
		abb26: {
			annuale: "534,00",
			tariff_id: "78914",
		},
		abb65: {
			annuale: "534,00",
			tariff_id: "79604",
		},
	},
	"Mi1-Mi8": {
		ordinario: {
			ord: "4,00",
			giorn: "14,00",
			tregiorn: "24,00",
		},
		abbordinario: {
			sett: "34,00",
			mensile: "87,00",
			annuale: "738,00",
		},
		abbagevolato: {
			mensile: "65,00",
			annuale: "554,00",
			annualeisee: "111,00",
		},
		abbconvenzione: {
			annuale: "738,00",
			tariff_id: "78804",
		},
		abb26: {
			annuale: "554,00",
			tariff_id: "78915",
		},
		abb65: {
			annuale: "554,00",
			tariff_id: "79605",
		},
	},
	"Mi1-Mi9": {
		ordinario: {
			ord: "4,40",
			giorn: "15,50",
			tregiorn: "26,50",
		},
		abbordinario: {
			sett: "37,50",
			mensile: "87,00",
			annuale: "761,00",
		},
		abbagevolato: {
			mensile: "65,00",
			annuale: "571,00",
			annualeisee: "114,00",
		},
		abbconvenzione: {
			annuale: "761,00",
			tariff_id: "78805",
		},
		abb26: {
			annuale: "571,00",
			tariff_id: "78916",
		},
		abb65: {
			annuale: "571,00",
			tariff_id: "79606",
		},
	},
	"Mi3-Mi4": {
		ordinario: { ord: "1,60", giorn: "5,60", tregiorn: "9,60" },
		abbordinario: {
			sett: "13,50",
			mensile: "40,00",
			annuale: "368,00",
		},
		abbagevolato: {
			mensile: "30,00",
			annuale: "276,00",
			annualeisee: "55,00",
		},
		abbconvenzione: {
			annuale: "368,00",
			tariff_id: "78806",
		},
		abb26: {
			annuale: "276,00",
			tariff_id: "78917",
		},
		abb65: {
			annuale: "276,00",
			tariff_id: "79607",
		},
	},
	"Mi3-Mi5": {
		ordinario: {
			ord: "2,00",
			giorn: "7,00",
			tregiorn: "12,00",
		},
		abbordinario: {
			sett: "17,00",
			mensile: "50,00",
			annuale: "460,00",
		},
		abbagevolato: {
			mensile: "37,50",
			annuale: "345,00",
			annualeisee: "69,00",
		},
		abbconvenzione: {
			annuale: "460,00",
			tariff_id: "78807",
		},
		abb26: {
			annuale: "345,00",
			tariff_id: "78918",
		},
		abb65: {
			annuale: "345,00",
			tariff_id: "79608",
		},
	},
	"Mi3-Mi6": {
		ordinario: {
			ord: "2,40",
			giorn: "8,40",
			tregiorn: "14,50",
		},
		abbordinario: {
			sett: "20,50",
			mensile: "60,00",
			annuale: "552,00",
		},
		abbagevolato: {
			mensile: "45,00",
			annuale: "414,00",
			annualeisee: "83,00",
		},
		abbconvenzione: {
			annuale: "552,00",
			tariff_id: "78808",
		},
		abb26: {
			annuale: "414,00",
			tariff_id: "78919",
		},
		abb65: {
			annuale: "414,00",
			tariff_id: "79609",
		},
	},
	"Mi3-Mi7": {
		ordinario: {
			ord: "2,80",
			giorn: "9,80",
			tregiorn: "17,00",
		},
		abbordinario: {
			sett: "24,00",
			mensile: "70,00",
			annuale: "644,00",
		},
		abbagevolato: {
			mensile: "53,00",
			annuale: "483,00",
			annualeisee: "97,00",
		},
		abbconvenzione: {
			annuale: "644,00",
			tariff_id: "78809",
		},
		abb26: {
			annuale: "483,00",
			tariff_id: "78920",
		},
		abb65: {
			annuale: "483,00",
			tariff_id: "79610",
		},
	},
	"Mi3-Mi8": {
		ordinario: {
			ord: "3,20",
			giorn: "11,00",
			tregiorn: "19,00",
		},
		abbordinario: {
			sett: "27,00",
			mensile: "77,00",
			annuale: "682,00",
		},
		abbagevolato: {
			mensile: "58,00",
			annuale: "512,00",
			annualeisee: "102,00",
		},
		abbconvenzione: {
			annuale: "682,00",
			tariff_id: "78810",
		},
		abb26: {
			annuale: "512,00",
			tariff_id: "78921",
		},
		abb65: {
			annuale: "512,00",
			tariff_id: "79611",
		},
	},
	"Mi3-Mi9": {
		ordinario: {
			ord: "3,60",
			giorn: "12,50",
			tregiorn: "21,50",
		},
		abbordinario: {
			sett: "30,50",
			mensile: "82,00",
			annuale: "712,00",
		},
		abbagevolato: {
			mensile: "62,00",
			annuale: "534,00",
			annualeisee: "107,00",
		},
		abbconvenzione: {
			annuale: "712,00",
			tariff_id: "78811",
		},
		abb26: {
			annuale: "534,00",
			tariff_id: "78922",
		},
		abb65: {
			annuale: "534,00",
			tariff_id: "79612",
		},
	},
	"Mi4-Mi5": {
		ordinario: { ord: "1,60", giorn: "5,60", tregiorn: "9,60" },
		abbordinario: {
			sett: "13,50",
			mensile: "40,00",
			annuale: "368,00",
		},
		abbagevolato: {
			mensile: "30,00",
			annuale: "276,00",
			annualeisee: "55,00",
		},
		abbconvenzione: {
			annuale: "368,00",
			tariff_id: "78812",
		},
		abb26: {
			annuale: "276,00",
			tariff_id: "78923",
		},
		abb65: {
			annuale: "276,00",
			tariff_id: "79613",
		},
	},
	"Mi4-Mi6": {
		ordinario: {
			ord: "2,00",
			giorn: "7,00",
			tregiorn: "12,00",
		},
		abbordinario: {
			sett: "17,00",
			mensile: "50,00",
			annuale: "460,00",
		},
		abbagevolato: {
			mensile: "37,50",
			annuale: "345,00",
			annualeisee: "69,00",
		},
		abbconvenzione: {
			annuale: "460,00",
			tariff_id: "78813",
		},
		abb26: {
			annuale: "345,00",
			tariff_id: "78924",
		},
		abb65: {
			annuale: "345,00",
			tariff_id: "79614",
		},
	},
	"Mi4-Mi7": {
		ordinario: {
			ord: "2,40",
			giorn: "8,40",
			tregiorn: "14,50",
		},
		abbordinario: {
			sett: "20,50",
			mensile: "60,00",
			annuale: "552,00",
		},
		abbagevolato: {
			mensile: "45,00",
			annuale: "414,00",
			annualeisee: "83,00",
		},
		abbconvenzione: {
			annuale: "552,00",
			tariff_id: "78814",
		},
		abb26: {
			annuale: "414,00",
			tariff_id: "78925",
		},
		abb65: {
			annuale: "414,00",
			tariff_id: "79615",
		},
	},
	"Mi4-Mi8": {
		ordinario: {
			ord: "2,80",
			giorn: "9,80",
			tregiorn: "17,00",
		},
		abbordinario: {
			sett: "24,00",
			mensile: "70,00",
			annuale: "644,00",
		},
		abbagevolato: {
			mensile: "53,00",
			annuale: "483,00",
			annualeisee: "97,00",
		},
		abbconvenzione: {
			annuale: "644,00",
			tariff_id: "78815",
		},
		abb26: {
			annuale: "483,00",
			tariff_id: "78926",
		},
		abb65: {
			annuale: "483,00",
			tariff_id: "79616",
		},
	},
	"Mi4-Mi9": {
		ordinario: {
			ord: "3,20",
			giorn: "11,00",
			tregiorn: "19,00",
		},
		abbordinario: {
			sett: "27,00",
			mensile: "77,00",
			annuale: "682,00",
		},
		abbagevolato: {
			mensile: "58,00",
			annuale: "512,00",
			annualeisee: "102,00",
		},
		abbconvenzione: {
			annuale: "682,00",
			tariff_id: "78816",
		},
		abb26: {
			annuale: "512,00",
			tariff_id: "78927",
		},
		abb65: {
			annuale: "512,00",
			tariff_id: "79617",
		},
	},
	"Mi5-Mi6": {
		ordinario: { ord: "1,60", giorn: "5,60", tregiorn: "9,60" },
		abbordinario: {
			sett: "13,50",
			mensile: "40,00",
			annuale: "368,00",
		},
		abbagevolato: {
			mensile: "30,00",
			annuale: "276,00",
			annualeisee: "55,00",
		},
		abbconvenzione: {
			annuale: "368,00",
			tariff_id: "78817",
		},
		abb26: {
			annuale: "276,00",
			tariff_id: "78928",
		},
		abb65: {
			annuale: "276,00",
			tariff_id: "79618",
		},
	},
	"Mi5-Mi7": {
		ordinario: {
			ord: "2,00",
			giorn: "7,00",
			tregiorn: "12,00",
		},
		abbordinario: {
			sett: "17,00",
			mensile: "50,00",
			annuale: "460,00",
		},
		abbagevolato: {
			mensile: "37,50",
			annuale: "345,00",
			annualeisee: "69,00",
		},
		abbconvenzione: {
			annuale: "460,00",
			tariff_id: "78818",
		},
		abb26: {
			annuale: "345,00",
			tariff_id: "78929",
		},
		abb65: {
			annuale: "345,00",
			tariff_id: "79619",
		},
	},
	"Mi5-Mi8": {
		ordinario: {
			ord: "2,40",
			giorn: "8,40",
			tregiorn: "14,50",
		},
		abbordinario: {
			sett: "20,50",
			mensile: "60,00",
			annuale: "552,00",
		},
		abbagevolato: {
			mensile: "45,00",
			annuale: "414,00",
			annualeisee: "83,00",
		},
		abbconvenzione: {
			annuale: "552,00",
			tariff_id: "78819",
		},
		abb26: {
			annuale: "414,00",
			tariff_id: "78930",
		},
		abb65: {
			annuale: "414,00",
			tariff_id: "79620",
		},
	},
	"Mi5-Mi9": {
		ordinario: {
			ord: "2,80",
			giorn: "9,80",
			tregiorn: "17,00",
		},
		abbordinario: {
			sett: "24,00",
			mensile: "70,00",
			annuale: "644,00",
		},
		abbagevolato: {
			mensile: "53,00",
			annuale: "483,00",
			annualeisee: "97,00",
		},
		abbconvenzione: {
			annuale: "644,00",
			tariff_id: "78820",
		},
		abb26: {
			annuale: "483,00",
			tariff_id: "78931",
		},
		abb65: {
			annuale: "483,00",
			tariff_id: "79621",
		},
	},
	"Mi6-Mi7": {
		ordinario: { ord: "1,60", giorn: "5,60", tregiorn: "9,60" },
		abbordinario: {
			sett: "13,50",
			mensile: "40,00",
			annuale: "368,00",
		},
		abbagevolato: {
			mensile: "30,00",
			annuale: "276,00",
			annualeisee: "55,00",
		},
		abbconvenzione: {
			annuale: "368,00",
			tariff_id: "78821",
		},
		abb26: {
			annuale: "276,00",
			tariff_id: "78932",
		},
		abb65: {
			annuale: "276,00",
			tariff_id: "79622",
		},
	},
	"Mi6-Mi8": {
		ordinario: {
			ord: "2,00",
			giorn: "7,00",
			tregiorn: "12,00",
		},
		abbordinario: {
			sett: "17,00",
			mensile: "50,00",
			annuale: "460,00",
		},
		abbagevolato: {
			mensile: "37,50",
			annuale: "345,00",
			annualeisee: "69,00",
		},
		abbconvenzione: {
			annuale: "460,00",
			tariff_id: "78822",
		},
		abb26: {
			annuale: "345,00",
			tariff_id: "78933",
		},
		abb65: {
			annuale: "345,00",
			tariff_id: "79623",
		},
	},
	"Mi6-Mi9": {
		ordinario: {
			ord: "2,40",
			giorn: "8,40",
			tregiorn: "14,50",
		},
		abbordinario: {
			sett: "20,50",
			mensile: "60,00",
			annuale: "552,00",
		},
		abbagevolato: {
			mensile: "45,00",
			annuale: "414,00",
			annualeisee: "83,00",
		},
		abbconvenzione: {
			annuale: "552,00",
			tariff_id: "78823",
		},
		abb26: {
			annuale: "414,00",
			tariff_id: "78934",
		},
		abb65: {
			annuale: "414,00",
			tariff_id: "79624",
		},
	},
	"Mi7-Mi8": {
		ordinario: { ord: "1,60", giorn: "5,60", tregiorn: "9,60" },
		abbordinario: {
			sett: "13,50",
			mensile: "40,00",
			annuale: "368,00",
		},
		abbagevolato: {
			mensile: "30,00",
			annuale: "276,00",
			annualeisee: "55,00",
		},
		abbconvenzione: {
			annuale: "368,00",
			tariff_id: "78824",
		},
		abb26: {
			annuale: "276,00",
			tariff_id: "78935",
		},
		abb65: {
			annuale: "276,00",
			tariff_id: "79625",
		},
	},
	"Mi7-Mi9": {
		ordinario: {
			ord: "2,00",
			giorn: "7,00",
			tregiorn: "12,00",
		},
		abbordinario: {
			sett: "17,00",
			mensile: "50,00",
			annuale: "460,00",
		},
		abbagevolato: {
			mensile: "37,50",
			annuale: "345,00",
			annualeisee: "69,00",
		},
		abbconvenzione: {
			annuale: "460,00",
			tariff_id: "78825",
		},
		abb26: {
			annuale: "345,00",
			tariff_id: "78936",
		},
		abb65: {
			annuale: "345,00",
			tariff_id: "79626",
		},
	},
	"Mi8-Mi9": {
		ordinario: { ord: "1,60", giorn: "5,60", tregiorn: "9,60" },
		abbordinario: {
			sett: "13,50",
			mensile: "40,00",
			annuale: "368,00",
		},
		abbagevolato: {
			mensile: "30,00",
			annuale: "276,00",
			annualeisee: "55,00",
		},
		abbconvenzione: {
			annuale: "368,00",
			tariff_id: "78826",
		},
		abb26: {
			annuale: "276,00",
			tariff_id: "78937",
		},
		abb65: {
			annuale: "276,00",
			tariff_id: "79627",
		},
	},
};

export default tariffe_stibm;
