const stazioni_ricarica = [
	{
		value: 1019,
		text: "AROSIO",
	},
	{
		value: 1114,
		text: "BARASSO - COMERIO",
	},
	{
		value: 1053,
		text: "BOLLATE CENTRO",
	},
	{
		value: 1054,
		text: "BOLLATE NORD",
	},
	{
		value: 1011,
		text: "BOVISIO MASCIAGO - MOMBELLO",
	},
	{
		value: 1278,
		text: "BRENO",
	},
	{
		value: 1259,
		text: "BRESCIA",
	},
	{
		value: 1203,
		text: "BUSTO ARSIZIO FN",
	},
	{
		value: 1016,
		text: "CABIATE",
	},
	{
		value: 1155,
		text: "CADORAGO",
	},
	{
		value: 1014,
		text: "CAMNAGO - LENTATE",
	},
	{
		value: 1027,
		text: "CANZO",
	},
	{
		value: 1028,
		text: "CANZO ASSO",
	},
	{
		value: 1058,
		text: "CARONNO PERTUSELLA",
	},
	{
		value: 1018,
		text: "CARUGO - GIUSSANO",
	},
	{
		value: 1154,
		text: "CASLINO AL PIANO",
	},
	{
		value: 1205,
		text: "CASTANO PRIMO",
	},
	{
		value: 1202,
		text: "CASTELLANZA",
	},
	{
		value: 1080,
		text: "CERIANO LAGHETTO - SOLARO",
	},
	{
		value: 1081,
		text: "CERIANO LAGHETTO GROANE",
	},
	{
		value: 1376,
		text: "CERTOSA DI PAVIA",
	},
	{
		value: 1012,
		text: "CESANO MADERNO",
	},
	{
		value: 1082,
		text: "CESANO MADERNO GROANE",
	},
	{
		value: 1057,
		text: "CESATE",
	},
	{
		value: 1102,
		text: "CISLAGO",
	},
	{
		value: 1118,
		text: "CITTIGLIO",
	},
	{
		value: 1116,
		text: "COCQUIO - TREVISAGO",
	},
	{
		value: 1160,
		text: "COMO BORGHI",
	},
	{
		value: 1159,
		text: "COMO CAMERLATA",
	},
	{
		value: 1161,
		text: "COMO LAGO",
	},
	{
		value: 1005,
		text: "CORMANO - CUSANO  MILANINO",
	},
	{
		value: 1272,
		text: "DARFO - CORNA",
	},
	{
		value: 1287,
		text: "EDOLO",
	},
	{
		value: 1023,
		text: "ERBA",
	},
	{
		value: 1229,
		text: "FERNO - LONATE POZZOLO",
	},
	{
		value: 1156,
		text: "FINO MORNASCO",
	},
	{
		value: 1208,
		text: "GALLIATE",
	},
	{
		value: 1056,
		text: "GARBAGNATE MILANESE",
	},
	{
		value: 1055,
		text: "GARBAGNATE PARCO DELLE GROANE",
	},
	{
		value: 1115,
		text: "GAVIRATE",
	},
	{
		value: 1339,
		text: "GAZZADA SCHIANNO - MORAZZONE",
	},
	{
		value: 1117,
		text: "GEMONIO",
	},
	{
		value: 1101,
		text: "GERENZANO - TURATE",
	},
	{
		value: 1158,
		text: "GRANDATE - BRECCIA",
	},
	{
		value: 1020,
		text: "INVERIGO",
	},
	{
		value: 1262,
		text: "ISEO",
	},
	{
		value: 1119,
		text: "LAVENO - MOMBELLO FN",
	},
	{
		value: 1374,
		text: "LOCATE TRIULZI",
	},
	{
		value: 1104,
		text: "LOCATE VARESINO - CARBONATE",
	},
	{
		value: 1153,
		text: "LOMAZZO",
	},
	{
		value: 1110,
		text: "MALNATE",
	},
	{
		value: 1190,
		text: "MALPENSA AEROPORTO T1",
	},
	{
		value: 1191,
		text: "MALPENSA AEROPORTO T2",
	},
	{
		value: 1487,
		text: "MANTOVA",
	},
	{
		value: 1017,
		text: "MARIANO COMENSE",
	},
	{
		value: 1015,
		text: "MEDA",
	},
	{
		value: 1022,
		text: "MERONE",
	},
	{
		value: 1401,
		text: "MILANO AFFORI",
	},
	{
		value: 1402,
		text: "MILANO BOVISA POLITECNICO",
	},
	{
		value: 1403,
		text: "MILANO BRUZZANO",
	},
	{
		value: 1404,
		text: "MILANO CADORNA",
	},
	{
		value: 1301,
		text: "MILANO CENTRALE",
	},
	{
		value: 1405,
		text: "MILANO DOMODOSSOLA FIERA",
	},
	{
		value: 1406,
		text: "MILANO QUARTO OGGIARO",
	},
	{
		value: 1113,
		text: "MOROSOLO - CASCIAGO",
	},
	{
		value: 1103,
		text: "MOZZATE",
	},
	{
		value: 1209,
		text: "NOVARA FN",
	},
	{
		value: 1052,
		text: "NOVATE MILANESE",
	},
	{
		value: 1008,
		text: "PADERNO",
	},
	{
		value: 1009,
		text: "PALAZZOLO MILANESE",
	},
	{
		value: 1269,
		text: "PISOGNE",
	},
	{
		value: 1025,
		text: "PONTELAMBRO - CASTELMARTE",
	},
	{
		value: 1157,
		text: "PORTICHETTO - LUISAGO",
	},
	{
		value: 1201,
		text: "RESCALDINA",
	},
	{
		value: 1152,
		text: "ROVELLASCA - MANERA",
	},
	{
		value: 1151,
		text: "ROVELLO PORRO",
	},
	{
		value: 1060,
		text: "SARONNO",
	},
	{
		value: 1059,
		text: "SARONNO SUD",
	},
	{
		value: 1013,
		text: "SEVESO",
	},
	{
		value: 1083,
		text: "SEVESO BARUCCANA",
	},
	{
		value: 1106,
		text: "TRADATE",
	},
	{
		value: 1105,
		text: "TRADATE ABBIATE GUAZZONE",
	},
	{
		value: 1206,
		text: "TURBIGO",
	},
	{
		value: 1204,
		text: "VANZAGHELLO - MAGNAGO",
	},
	{
		value: 1010,
		text: "VAREDO",
	},
	{
		value: 1112,
		text: "VARESE - CASBENO",
	},
	{
		value: 1111,
		text: "VARESE FN",
	},
	{
		value: 1109,
		text: "VEDANO OLONA",
	},
	{
		value: 1107,
		text: "VENEGONO INFERIORE",
	},
	{
		value: 1108,
		text: "VENEGONO SUP. - CASTIGLIONE",
	},
	{
		value: 1646,
		text: "VERDELLO - DALMINE",
	},
	{
		value: 1373,
		text: "VILLAMAGGIORE",
	},
];

export default stazioni_ricarica;
