import httpClient from "@/services/httpClient";

var mime = require("mime-types");

class PascalService {
	async getPascalPillars() {
		return httpClient.get("/pascal/pillars/pascalPillars.json");
	}
	async getPascalMeasureImpacts(office_id, measure_id) {
		return httpClient.get(`/pascal/measures/getPascalMeasureImpacts/${office_id}/${measure_id}.json`);
	}

	async getPascalMeasureLabels(measure_id) {
		return httpClient.get(`/pascal/measures/getPascalMeasureLabels/${measure_id}.json`);
	}
	async updatePascalMeasures(office_id, form_data) {
		return httpClient({
			method: "post",
			url: `/pascal/offices/pascalMeasures/${office_id}.json`,
			data: form_data,

		});
	}
	async getPascalMeasures(office_id) {
		return httpClient({
			method: "get",
			url: `/pascal/offices/pascalMeasures/${office_id}.json`,

		});
	}
}

export default new PascalService();
