<template>
    <b-card border-variant="primary" header="Delega MobilitySquare" header-bg-variant="primary"
        header-text-variant="white" style="max-width: 25rem">
        <b-card-text>
            <p><b>Delega MobilitySquare ad accedere all'area riservata Trenord</b></p>
            Se hai già un account Trenord, per usare il servizio devi consentire a MobilitySquare l'accesso all'area
            riservata Trenord
            per permetterci di effettuare l'acquisto dell'abbonamento per te.<br>
        </b-card-text>
        <b-card-footer>
            
                <b-form-group required>
                <label for="username"><b>*</b>Inserisci la mail con cui accedi al tuo account Trenord</label>
                <b-input placeholder="Email Trenord" name="username" class="mb-2" v-model="form.trenord_user" required>
                </b-input>
                </b-form-group>
                <b-form-group required>
                <label for="password"><b>*</b>Inserisci la password di Trenord</label>
                <b-input placeholder="Password Trenord" name="password" class="mb-2" type="password" v-model="form.trenord_pwd" required>
                </b-input>
                </b-form-group>

                <b-form-checkbox :value="true" required v-model="form.autorizzo_trenord" v-if="!form.autorizzo_trenord" name="autorizzo_trenord">
                    Delego l’agenzia MobilitySquare SRL - Società Benefit, con sede legale in Loc Ciocchini 18, Novello
                    e partita IVA 03642740041, a operare per mio conto sull'account Trenord intestato a me
                    medesimo/a.<br>
                    Autorizzo l'agenzia sopra menzionata ad accedere al mio account Trenord, utilizzando le mie
                    credenziali personali, per effettuare le operazioni di:
                    <ul>
                        <li>Acquisto di biglietti e abbonamenti</li>
                        <li>Consultazione di orari e prenotazioni</li>
                        <li>Richiesta di assistenza e gestione pratiche</li>
                        <li>Eventuali altre operazioni necessarie e previste dalle funzionalità dell'account Trenord
                        </li>
                    </ul>
                    Sono consapevole che l'agenzia MobilitySquare si impegna a utilizzare le credenziali esclusivamente
                    per le finalità sopra indicate, nel pieno rispetto della normativa vigente sulla privacy e sulla
                    protezione dei dati personali.

                    La presente delega è valida fino al a revoca scritta da parte mia, da inviarsi a mezzo mail a
                    abbonamenti@mobilitysquare.eu.
                </b-form-checkbox>

                <b-alert variant="success" :show="form.autorizzo_trenord">
                    <b-icon icon="check-circle" variant="success"></b-icon>
                    Il giorno {{ form.date_autorizzo_trenord }} hai autorizzato MobilitySquare ad accedere al tuo account
                    Trenord per i soli scopi dello Sportello
                    Abbonamenti e secondo le modalità indicate nei termini.
                </b-alert>

        </b-card-footer>
    </b-card>
</template>

<script>

export default {
    name: "AbbonamentoDelegaAccedere",
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
        };
    },
};
</script>
