<template>
    <div>
        <p>Questo strumento serve per generare il PSCL in formato editabile e successivamente il PDF secondo il template
            standard</p>
        <hr>

        <b-row>
            <b-col cols="8">
        <ol class="list-group">
            <li class="list-group-item pointing" >
                <b-badge :variant="getVariantFromLock(0)" pill>{{ message[0] }}</b-badge>&nbsp;
                <b>Genera</b> il contenuto del piano in formato Markdown + Html               
                <b-icon icon="arrow-clockwise" animation="spin-pulse" v-if="current==0"></b-icon> 
                <b-button variant="outline-primary" @click="generateMD()" class="pull-right btn-width">Genera .md</b-button>
            </li>

            <li class="list-group-item pointing">                
                <b-badge :variant="getVariantFromLock(1)" pill>{{ message[1] }}</b-badge>&nbsp;
                <b>Esporta</b> il contenuto del piano su NextCloud (può volerci qualche minuto)                
                <b-icon icon="arrow-clockwise" animation="spin-pulse" v-if="current == 1"></b-icon>
                <b-button variant="outline-primary" @click="pushToNextCloud()" class="pull-right btn-width">Butta su nextcloud</b-button>
            </li>

            <li class="list-group-item pointing">
                <b>Apri NextCloud</b> e modifica il piano
                <b-button variant="outline-primary" @click="openNextCloud()" class="pull-right btn-width">
                    <img src="/nextcloud.png" alt="Apri NextCloud" width="40">&nbsp;
                    Apri NextCloud
                </b-button>
            </li>

            <li class="list-group-item pointing">
                <b-badge :variant="getVariantFromLock(2)" pill>{{ message[2] }}</b-badge>&nbsp;                
                <b>Importa</b> le modifiche fatte su NextCloud
                <b-icon icon="arrow-clockwise" animation="spin-pulse" v-if="current == 2"></b-icon>
                <b-button variant="outline-primary" @click="pullFromNextCloud()" class="pull-right btn-width">Prendi da NextCloud</b-button>
            </li>
            <li class="list-group-item pointing" >
                <b-badge :variant="getVariantFromLock(3)" pill>{{ message[3] }}</b-badge>&nbsp;
                Genera il piano in <b>formato HTML</b> secondo il template
                <b-icon icon="arrow-clockwise" animation="spin-pulse" v-if="current == 3"></b-icon>
                <b-button variant="outline-primary" @click="generateHtml()" class="pull-right btn-width">Genera HTML</b-button>
            </li>
            <li class="list-group-item pointing" >
                <b-badge :variant="getVariantFromLock(3)" pill>{{ message[3] }}</b-badge>&nbsp;
                Converte il piano in <b>formato PDF</b>
                <b-icon icon="arrow-clockwise" animation="spin-pulse" v-if="current == 3"></b-icon>
                <b-button variant="outline-primary" @click="generatePdf()" class="pull-right btn-width">Genera PDF</b-button>
            </li>
            <li class="list-group-item pointing" >
                <b-badge :variant="getVariantFromLock(4)" pill>{{ message[4] }}</b-badge>&nbsp;
                Esporta il <b>PDF finale</b> su NextCloud (può volerci qualche minuto)
                <b-icon icon="arrow-clockwise" animation="spin-pulse" v-if="current == 4"></b-icon>
                <b-button variant="outline-primary" @click="pushToNextCloud()" class="pull-right btn-width">Esporta PDF</b-button>
            </li>
        </ol>
    </b-col>
    </b-row>
    </div>
</template>

<script>
import Util from "@/mixing/util";
import MDGenerate from "../md_generate.service";

export default {
    name: "MDGenerate",
    mixins: [Util],
    data() {
        return {
            loading: false,
            message: "Fai click su uno dei pulsanti per generare il piano",
            lock:  [false, false, false, false, false],  //Stato dei 5 step
            company_id: this.$route.params.company_id,
            office_id: this.$route.params.office_id,
            survey_id: this.$route.params.survey_id,
            ignore_office: this.$route.params.ignore_office,
            statsIntervalId: null,
        };
    },
    created() {  
        this.getYear();
        if(this.year == 'TUTTI'){
            this.year = null;
        }
        this.lock=  [false, false, false, false, false, false];  //Stato dei 5 step
        this.getStatus();      
        this.statsIntervalId = setInterval(async () => {
            try {
                this.getStatus();
            } catch (e) {                
                console.log(e);
            }
        }, 10000);
    },
    beforeDestroy: function () {
        if (this.statsIntervalId) {
            clearInterval(this.statsIntervalId);
        }

    },
    methods: {
        async getStatus() {
            try {
                const response = await MDGenerate.getStatus(this.company_id, this.office_id, this.survey_id);
                if (response.data == null){
                    this.lock = [false, false, false, false, false, false];
                    this.message = "Scegli un'operazione";
                    return;
                }
                
                this.message = response.data.message;
                this.lock = response.data.lock;
            } catch (error) {
                this.message = error.message;
            }
        },

        async generateMD() {            
            this.getStatus();
            try {
                
                //this.lock[0] = true;
                const response = await MDGenerate.generatePSCL('md', this.company_id, this.office_id, this.survey_id, this.ignore_office,this.year, 0);
                this.message = response.message;
                
            } catch (error) {
                this.lock[0] = false;
                this.message = error.message;
            }
        },

        async pushToNextCloud() {
            this.getStatus();
            try {
                //this.lock[1] = true;
                const response = await MDGenerate.pushToNextCloud(this.company_id, this.office_id, this.survey_id, this.ignore_office,this.year,1);
                this.message = response.message;
            } catch (error) {
                this.lock[1] = false;
                this.message = error.message;
            }            
        },

        async openNextCloud() {
            let url = "https://cloud.mobilitysquare.eu/apps/files/?dir=/Moma/Mobility48/pscl";
            //open link in a new window
            window.open(url, '_blank', 'noreferrer');
        },

        async pullFromNextCloud() {
            this.getStatus();
            try {
                //this.lock[2] = true;
                const response = await MDGenerate.pullFromNextCloud(this.company_id, this.office_id, this.survey_id, this.ignore_office,this.year,2);
                this.message = response.message;
            } catch (error) {
                this.lock[2] = false;
                this.message = error.message;
            }
        },
        async generateHtml() {
            this.getStatus();
            try {
                //this.lock[3] = true;
                //Importante debo usare il generatore html2, perchè l'uno è quello classico
                const response = await MDGenerate.generatePSCL('html2', this.company_id, this.office_id, this.survey_id, this.ignore_office,this.year,3);
                this.message = response.message;
            } catch (error) {
                this.lock[3] = false;
                this.message = error.message;
            }
        }, 
        
        async generatePdf() {
            this.getStatus();
            try {
                //this.lock[3] = true;
                const response = await MDGenerate.generatePSCL('pdf', this.company_id, this.office_id, this.survey_id, this.ignore_office,this.year,3);
                this.message = response.message;
            } catch (error) {
                this.lock[3] = false;
                this.message = error.message;
            }
        },

        async pushToNextCloud() {
            this.getStatus();
            try {
                this.lock[4] = true;
                const response = await MDGenerate.pushToNextCloud(this.company_id, this.office_id, this.survey_id, this.ignore_office,this.year,4);
                this.message = response.message;
            } catch (error) {
                this.lock[4] = false;
                this.message = error.message;
            }                        
        },        

        getVariantFromLock(id) {
            console.log(this.lock[id], id);
            if (typeof(this.lock[id]) == undefined || this.lock[id]< 0) {
                return "danger";
            } else if (this.lock[id] > 0) {
                return "warning";
            } else {
                return "success";
            }
        }
    },
    computed: {
        //Restituisce il primo lock > 0
        current() {
            for (let i = 0; i < this.lock.length; i++) {
                if (this.lock[i] > 0) {
                    return i;
                }
            }
            return -1;
        }
    }
    

};
</script>

<style>
.pointing {
    /* Pointing hand cursor */
    cursor: pointer;
}
.btn-width {
    width: 150px;
}
</style>
