<template>
  <div>
    <h3>Le misure del Comune di {{ office_name }} - progetto Pascal</h3>
    <div class="row">
      <div class="col">
        <b-button variant="primary" class="float-right" :href="`/pascal/report/${office_id}`" target="_blank">Scarica analisi del Comune</b-button>
      </div>
    </div>
    <b-list-group class="mt-2">
      <b-list-group-item v-for="pillar in pillars" :key="pillar.id" @click="openPillar(pillar.id)">
        <b-badge>{{ pillar.id }}</b-badge>
        &nbsp; {{ pillar.name }}
        <span class="float-right">
          <span v-if="countMeasures(pillar.id)">
            <b-badge variant="primary">{{ countMeasures(pillar.id) }}</b-badge> misure
          </span>
          &nbsp;
          <span v-if="countServices(pillar.id)">
            <b-badge variant="warning">{{ countServices(pillar.id) }}</b-badge> servizi
          </span>
        </span>
        <div v-if="showPillar == pillar.id">
          <ul class="list-unstyled">
            <b-media tag="li" v-for="m in filterMeasures(pillar.id)" :key="m[0]" class="mt-3">
              <template v-slot:aside>
                <b-img v-if="!m[1].img" width="200" height="130" v-bind="mainProps"></b-img>
                <b-img v-if="m[1].img" :src="`${serverPath}/img/${m[1].img}`" width="200" height="130"></b-img>
              </template>
              <h5 class="mt-0 mb-1">
                {{ m[1].name }}
                <b-badge v-if="isRecommended(m[1].id)" variant="success">Suggerito per il tuo caso</b-badge>
              </h5>
              <p class="">
                {{ m[1].description }}
              </p>

                            <h5 v-b-toggle="'collapse-monitoraggio-' + m[1].id" @click="[fetchMonitorings(m[1].id), getMonitoringLabels(m[1].id)]">
                                Monitoraggio <b-icon icon="caret-down-square-fill"></b-icon>
                            </h5>
                            <b-collapse :id="`collapse-monitoraggio-${m[1].id}`" class="mt-2" @force-render="forceRerender()">
                                <monitoring
                                    @modal-open="showModalIndicatori($event)"
                                    :measure-id="m[1].id"
                                    :measure="getMeasure(m[1].id)"
                                    :monitoring="monitorings.get(m[1].id)"
                                    :busy="loading"
                                    @row-clicked="onRowClicked($event)"></monitoring>
                            </b-collapse>

                            <h5 @click="openImpact(m[1].id)">Impatto <b-icon icon="caret-down-square-fill"></b-icon></h5>
                            <b-collapse :id="`collapse-impatto-${m[1].id}`" class="mt-2" @force-render="forceRerender()">
                                <impact
                                    v-if="renderComponent"
                                    :officeId="office_id"
                                    :measureId="m[1].id"
                                    :measure="getMeasure(m[1].id)"
                                    :monitoring="monitorings.get(m[1].id)"
                                    :busy="loading"></impact>
                            </b-collapse>
                        </b-media>
                    </ul>
                </div>
            </b-list-group-item>
        </b-list-group>

        <!-- Modal Indicatori -->
        <b-modal @ok="handleOk()" @cancel="handleClose()" :title="`Indicatori ${currentMeasureName}`" id="indicatori-modal">
            <table class="table table-striped">
                <tr>
                    <td>nome punto di monitoraggio</td>
                    <td>
                        <b-input v-model="monitorings_tmp['name']" size="sm" type="text" />
                    </td>
                </tr>
                <tr>
                    <td>Data</td>
                    <td>
                        <b-input v-model="monitorings_tmp['dt']" size="sm" type="date" />
                    </td>
                </tr>

                <tr v-for="l in labels" :key="l.key">
                    <td v-if="l.field_type == 'radio'">
                        {{ l.label }}
                    </td>
                    <td v-if="l.field_type == 'radio'">
                        <b-form-radio v-model="monitorings_tmp[l.key]" name="yes-no-radios" value="1">Sì</b-form-radio>
                        <b-form-radio v-model="monitorings_tmp[l.key]" name="yes-no-radios" value="0">No</b-form-radio>
                    </td>

                    <td v-if="l.field_type != 'radio'">
                        {{ l.label }}
                    </td>
                    <td v-if="l.field_type != 'radio'">
                        <b-input v-model="monitorings_tmp[l.key]" size="sm" :type="l.field_type" />
                    </td>
                </tr>
            </table>
        </b-modal>
        <!-- / Modal Indicatori -->
    </div>
</template>
<script>
import Monitoring from "../components/Pascal/Monitoring";
import Impact from "../components/Pascal/Impact";
import measures from "../data/pascal-measures";
// import pillars from "../data/pascal-pillars";
import Filters from "@/mixing/filters";

import PascalService from "../pascal.service";
import Util from "@/mixing/util";

export default {
	name: "Pascal",
	components: {
		Impact,
		Monitoring,
	},
	mixins: [Filters, Util],
	data() {
		return {
			office_id: this.$route.params.office_id,
			office_name: null,
			publicPath: process.env.BASE_URL,
			serverPath: process.env.VUE_APP_ROOT,
			mainProps: { blank: true, blankColor: "#777", width: 75, height: 75, class: "m1" },
			showPillar: null,
			//Trick for making charts update on div show
			//https://michaelnthiessen.com/force-re-render/
			renderComponent: false,

			// pillars: pillars, //from vue file
			// all_measures: measures,
			all_measures: null,
			measures: new Map(),
			measure_id: null,
			monitorings: new Map(),
			row_index: null,
			monitorings_tmp: {
				name: null,
				dt: null,
			},
			pillars: null,
			labels: null,
			test_measures: null,
		};
	},
	methods: {
		getMeasure(measure_id) {
			return this.all_measures.filter((measure) => measure.id === measure_id)[0];
		},
		openPillar(pillar_id) {
			this.showPillar = pillar_id;
		},
		openMeasure(measure_id) {
			this.measure_id = measure_id;
			this.monitorings_tmp = this.measures.get(measure_id).labels;
			this.calculate16();
		},
		filterMeasures(pillar_id) {
			let res = new Map();
			this.all_measures.forEach((m) => {
				if (m.pillar_id == pillar_id) {
					res.set(m.id, m);
				}
			});
			return res;
		},
		countMeasures(pillar_id) {
			return this.filterMeasures(pillar_id).size;
		},
		countServices(pillar_id) {
			let res = this.filterMeasures(pillar_id);
			let c = 0;
			res.forEach((m) => {
				if (m.type == 2) {
					c++;
				}
			});
			return c;
		},
		isRecommended(measure_id) {
			return measure_id == -1;
		},
		openImpact(id) {
			this.$root.$emit("bv::toggle::collapse", "collapse-impatto-" + id);
			this.forceRerender();
		},
		forceRerender() {
			// Remove my-component from the DOM
			this.renderComponent = false;

			this.$nextTick(() => {
				// Add the component back in
				this.renderComponent = true;
			});
		},

		async fetchMonitorings(measure_id) {
			this.measure_id = measure_id;
			let result = null;
			this.loading = true;
			try {
				result = await PascalService.getMonitorings(this.office_id, measure_id);
				this.labels = this.getMonitoringLabels(measure_id);
			} catch (error) {
				this.showError(error);
			} finally {
				this.loading = false;
			}
			let series = result.data.monitorings;
			let outs = [];
			series.map((element) => {
				let jv = element.jvalues;
				if (jv != null) {
					Object.entries(jv).forEach(([key, value]) => {
						element[key] = value;
					});
				}
				delete element.jvalues;
				delete element.created;
				delete element.modified;
				delete element.office_id;
				delete element.measure_id;

				//TODO: Generalizzare con la vera traccia dell'output
				outs.push({ riduzione_km_gg_auto: 0, C: 0, CO: 0, CO2: 0, NOx: 0, PM10: 0 });
			});

			this.monitorings.set(this.measure_id, {
				series,
				out: outs,
			});
		},
		async getMunicipality() {
			try {
				let res = await PascalService.getOffice(this.office_id);
				this.office_name = res.data.office.name;
				if (res.data.office.pascal_measures !== undefined) {
					this.active_measures = res.data.office.pascal_measures;
				} else {
					this.active_measures = null;
				}

				let self = this;
				this.all_measures.forEach((x) => {
					if (self.active_measures.includes(x.id)) {
						self.measures.set(x.id, x);
					}
				});
			} catch (error) {
				this.showError(error);
			}
		},
		async getMonitoringLabels(measure_id) {
			if (measure_id != null && measure_id != 0) {
				try {
					this.labels = null;
					let lbls = await PascalService.getPascalMeasureLabels(measure_id);
					this.labels = lbls.data.labels;
					return this.labels;
				} catch (error) {
					console.log(error);
				}
			}
		},
		handleClose() {
			this.forceRerender();
		},
		async handleOk() {
			//console.log("handleOk, rowindex", this.row_index);
			if (this.row_index == null) {
				this.monitorings_tmp.measure_id = this.measure_id;
				this.monitorings_tmp.office_id = this.office_id;
				let res;
				try {
					res = await PascalService.addMonitoring(this.monitorings_tmp);
					this.forceRerender();
				} catch (error) {
					console.log(error);
					this.showError(error);
				}
				//Se non c'è il monitoring devo creare la serie
				if (this.monitorings.get(this.measure_id) == undefined || this.monitorings.get(this.measure_id).series == undefined) {
					this.row_index = 0;
					this.monitorings.set(this.measure_id, { series: [this.monitorings_tmp] });
				} else {
					//Devo fare push del risultato che ottengo da addMonitoring per avere l'id
					this.monitorings_tmp.id = res.data.monitoring.id;
					//Push returns the length of the array
					this.row_index = this.monitorings.get(this.measure_id).series.push(this.monitorings_tmp);
				}

				this.$root.$emit("bv::refresh::table", "tab-" + this.measure_id);
			} else {
				await PascalService.editMonitoring(this.monitorings_tmp.id, this.monitorings_tmp);
				this.forceRerender();
				this.monitorings.get(this.measure_id).series[this.row_index] = this.monitorings_tmp;
			}
			this.row_index = null;
		},

		showModalIndicatori($event) {
			//console.log("showmodal", $event);
			this.measure_id = $event.measure_id;
			this.row_index = $event.row_index;
			this.labels = this.getMonitoringLabels(this.measure_id);
			if (this.row_index != null) {
				this.monitorings_tmp = this.monitorings.get(this.measure_id).series[this.row_index];
			} else {
				this.monitorings_tmp = { name: null, dt: null };
			}
			this.$bvModal.show("indicatori-modal");
		},
		onRowClicked($event) {
			//console.log("rowClicked", $event);
			this.measure_id = $event.measure_id;
			this.row_index = $event.row_index;
			this.forceRerender();
		},
	},
	computed: {
		currentMeasureName() {
			if (this.measure_id == null) {
				return null;
			}
			let m = this.all_measures.filter((measure) => measure.id === this.measure_id)[0];
			if (m == undefined) {
				return "-";
			}
			return m.name;
		},
	},
	async mounted() {},
	async created() {
		try {
			const response = await Promise.all([
				PascalService.getPascalPillars(), //0
				PascalService.getMeasures(), //1
			]);

			this.pillars = response[0].data.pillars;
			let measures = response[1].data.measures;
			this.all_measures = measures.filter((measure) => measure.target === "pascal");
		} catch (error) {
			console.log(error);
		} finally {
			this.monitorings = new Map();
			for (let i = 1; i <= measures.length; i++) {
				this.monitorings.set(i, {
					series: [],
					out: [{ riduzione_km_gg_auto: 0, C: 0, CO: 0, CO2: 0, NOx: 0, PM10: 0 }],
				});
			}
		}
	},
};
</script>
<style></style>
