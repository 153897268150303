<template>
    <div style="margin: 2em">
        <b-overlay :show="loading" rounded="sm">
            <div v-for="measure in all_measures" :key="measure.id">
                <h5>
                    <b-media tag="div" class="mt-3">
                        <template v-slot:aside>
                            <b-img v-if="measure.img" :src="`${serverPath}/img/${measure.img}`" width="200" height="130"></b-img>
                        </template>
                        <h3 class="mt-0 mb-1">
                            {{ measure.name }}
                        </h3>
                        <p class="">{{ measure.description }}</p>
                    </b-media>
                </h5>
                <comparison
                    v-if="!loading"
                    :measure-id="measure.id"
                    :monitorings="measureMonitorings.get(measure.id)"
                    :busy="loading"
                    :labels="measureLabels.get(measure.id)"
                    :monitoringNames="measureMonitoringNames.get(measure.id)"
                    :measure="all_measures[measure.id - 1]"></comparison>
                <hr />
            </div>
        </b-overlay>
    </div>
</template>

<script>
import Comparison from "../components/Pascal/Comparison";
import measures from "../data/pascal-measures";

import PascalService from "../pascal.service";

export default {
	name: "testreport",
	components: {
		Comparison,
	},
	data() {
		return {
			loading: false,
			all_measures: measures,
			listComuni: null,
			labels: null,
			serverPath: process.env.VUE_APP_ROOT,
			monitoringsByoffice: [],
			measureMonitorings: new Map(),
			measureLabels: new Map(),
			measureMonitoringNames: new Map(),
		};
	},
	methods: {
		async fetchMonitorings(office_id, measure_id) {
			let result = null;
			try {
				result = await PascalService.getMonitorings(office_id, measure_id);
			} catch (error) {
				// this.showError(error);
			} finally {
				// this.loading = false;
			}
			let series = result.data.monitorings;
			series.map((element) => {
				let jv = element.jvalues;
				if (jv != null) {
					Object.entries(jv).forEach(([key, value]) => {
						element[key] = value;
					});
				}
				delete element.jvalues;
				delete element.created;
				delete element.modified;
				delete element.office_id;
				delete element.measure_id;
			});
			return series;
		},
		getMeasure(measure_id) {
			return this.all_measures.filter((measure) => measure.id == measure_id)[0];
		},
	},
	async created() {
		this.loading = true;
		let tmp = null;
		try {
			tmp = await PascalService.getMunicipalitiesList();
		} catch (error) {
			// this.showError(error);
		}
		this.listComuni = tmp.data.municipalities;

		let self = this;
		tmp = null;
		for (let m of self.all_measures) {
			let tmpNamesArray = [];
			let tmpMeasureMonitorings = [];
			for (let office of self.listComuni) {
				self.labels = [];
				self.labels.push({ key: "officeName", label: "OfficeName" }, { key: "dt", label: "Data" });
				m.labels.forEach((element) => {
					self.labels.push(element);
				});

				tmp = await self.fetchMonitorings(office.id, m.id);

				tmp.forEach((element) => {
					element.officeName = office.name;
					element.measureId = m.id;
					if (!tmpNamesArray.includes(element.name)) {
						tmpNamesArray.push(element.name);
					}
					tmpMeasureMonitorings.push(element);
				});
				self.measureMonitoringNames.set(m.id, tmpNamesArray);
				self.measureMonitorings.set(m.id, tmpMeasureMonitorings);
				if (tmp.length > 0) {
					self.measureLabels.set(m.id, self.labels);
				}
			}
		}
		this.loading = false;
	},
};
</script>
