<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <b-dropdown id="dropdown-genera" text="Generazione automatica" class="m-md-2" split>
                    <b-dropdown-item @click="generateMobilityLabel()" class="ml-3" size="sm">                        
                        <b-icon icon="tags" aria-hidden="true"></b-icon>
                        Genera Mobility Label
                    </b-dropdown-item>

                    <b-dropdown-item @click="generateSurvey()" class="ml-3" size="sm">                        
                        <b-icon icon="pie-chart" aria-hidden="true"></b-icon>
                        Genera Analisi Domande
                    </b-dropdown-item>

                    <b-dropdown-item @click="generateActions()" class="ml-3" size="sm">
                        <b-icon icon="brush" aria-hidden="true"></b-icon>
                        Genera Misure PSCL
                    </b-dropdown-item>

                    <b-dropdown-divider></b-dropdown-divider>
                    
                    <b-dropdown-item variant="danger" @click="generateMd()" class="ml-3" size="sm">
                        <b-icon  variant="danger" icon="exclamation-triangle-fill" aria-hidden="true"></b-icon>
                        (Ri)Genera tutto il piano 
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="col-md-6 d-flex flex-row-reverse">
                <b-button-group>
                    <b-button variant="primary" @click="generatePdf()" class="ml-3">
                        <b-spinner small v-if="generatingPdf"></b-spinner>
                        <span class="sr-only">Loading...</span>

                        <b-icon icon="file-pdf" aria-hidden="true"></b-icon>
                        Genera Documento PDF
                        <b-badge variant="warning">lento</b-badge>
                    </b-button>
                    <b-button  :variant="pdfButtonVariant" :href="pdfUrl" target="anteprima_html" :disabled="pdfUrl == ''" title="Anteprima">
                        <b-icon icon="arrow-up-right-square" aria-hidden="true"></b-icon>
                    </b-button>
                </b-button-group>
                <b-button-group>
                    <b-button variant="primary" @click="generateHTML()">
                        <b-spinner small v-if="generatingHtml"></b-spinner>
                        <span class="sr-only">Loading...</span>

                        <b-icon icon="file-code" aria-hidden="true"></b-icon>
                        Genera HTML
                        
                    </b-button>
                    <b-button :variant="htmlButtonVariant" :href="htmlUrl" target="anteprima_html" :disabled="htmlUrl == ''" title="Anteprima">
                        <b-icon icon="arrow-up-right-square" aria-hidden="true"></b-icon>
                    </b-button>                    
                </b-button-group>
            </div>
        </div>
        <div class="row mt-3" style="height: 80vh;">
            <div class="col-md-2 h-100">

                <div class="tree">
                    <h5>Struttura del PSCL</h5>
                    <directory-tree :tree="dirTree" @open="previewFile($event)" parent=""></directory-tree>
                </div>
            </div>
            <div class="col-md-10 h-100">
                <v-md-editor id="md-editor" v-model="text" class="w-100 h-100" @save="saveMd()"></v-md-editor>
            </div>
        </div>
    </div>

</template>

<script>
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
// highlightjs
import hljs from 'highlight.js';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';

import DirectoryTree from '../components/DirectoryTree.vue';
import Util from "@/mixing/util";
import MDGenerate from "../md_generate.service";

VMdEditor.use(githubTheme, {
    Hljs: hljs,
});
VMdEditor.lang.use('en-US', enUS);


export default {
    name: 'MdEditor',
    data() {
        return {
            text: '',
            dirTree: {},
            company_id: this.$route.params.company_id,
            office_id: this.$route.params.office_id,
            survey_id: this.$route.params.survey_id,
            ignore_office: this.$route.params.ignore_office,
            activeFileName: '',
            oldText: '',
            htmlUrl: '',
            pdfUrl: '',
            generatingHtml: false,
            generatingPdf: false,
        };
    },
    mixins: [Util],
    async created() {
        this.getYear();
        if (this.year == 'TUTTI') {
            this.year = null;
        }
        this.dirTree = await MDGenerate.getFolder(this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year);
        this.dirTree = this.dirTree?.data.directoryTree;
        //console.log(this.dirTree);
        this.$root.$on('open', async (name) => {
            if (this.dirty) {
                await this.askToSave();
            }
            this.previewFile(name);
        });
    },
    components: {
        VMdEditor,
        DirectoryTree,
    },
    computed: {
        htmlButtonVariant() {
            return this.htmlUrl ? 'success' : 'outline-primary';
        },
        pdfButtonVariant() {
            return this.pdfUrl ? 'success' : 'outline-primary';
        },
        dirty() {
            return this.text !== this.oldText;
        }
    },
    methods: {
        async previewFile(value) {
            this.activeFileName = value;
            if (value.endsWith('.md') || value.endsWith('.html')) {
                //Se è modificato chiedi di salvare il file aperto prima di aprirne un altro

                let f = await MDGenerate.getFile(this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, value);
                this.text = f.data;
                this.oldText = this.text;
            }
        },

        async generateHTML() {
            this.generatingHtml = true;
            if (this.oldText != this.text){
                await this.askToSave();
            }
            await MDGenerate.generatePSCL("html2", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 1);
            let l = await MDGenerate.getUrl("html2", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 1);
            this.htmlUrl = `${l.data.url}/pscl.html`;
            this.generatingHtml = false;
        },

        async generatePdf() {
            this.generatingPdf = true;
            if (this.oldText != this.text){
                await this.askToSave();
            }
            await MDGenerate.generatePSCL("pdf", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 1);
            let l = await MDGenerate.getUrl("pdf", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 1);
            this.pdfUrl = `${l.data.url}/pscl.pdf`;
            this.generatingPdf = false;
        },

        async generateMd() {
            this.$bvModal.msgBoxConfirm('Se rigeneri il piano tutte le modifiche che hai fatto saranno sovrascitte.', {
                title: 'Sei sicuro?',
                okVariant: 'danger',
                okTitle: 'RIGENERA',
                cancelTitle: 'NON FARLO!',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        MDGenerate.generatePSCL("md", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 1);
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },

        async saveMd() {
            await MDGenerate.saveFile(this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, this.activeFileName, this.text);
            this.$bvToast.toast("Salvataggio avvenuto correttamente", {
                title: "Salvataggio ok",
                autoHideDelay: 2000,
                appendToast: true,
                variant: "success",
            });
            this.oldText = this.text;
        },
        absoluteUrls(text, next) {
            console.log('absoluteUrls');
            next = text.replace(/(src|href)="(?!http|\/)([^"]+)"/g, '$1="/https://api.mobility48.it/Mobility48/$2"');
        },
        async askToSave() {
            await this.$bvModal.msgBoxConfirm('Vuoi salvare le modifiche?', {
                title: 'Salvataggio',
                okVariant: 'success',
                okTitle: 'SALVA',
                cancelTitle: 'NON SALVARE',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        this.saveMd();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        }
    },
    watch: {

    }
};
</script>


<style scoped>


.report {
    overflow-y: auto;
    border: 1px solid silver;
}
</style>
</div>