//Pagina Nuda per le tariffe:http://percorsieorari.gtt.to.it/tariffe_extra/
//Pagina Web Tariffe: http://www.gtt.to.it/cms/biglietti-abbonamenti/biglietti/tariffe-extraurbano
//Elenco Paesi: http://percorsieorari.gtt.to.it/tariffe_extra/ajaxPaesi.php?term=Novello
//PDF - Tariffe: http://www.gtt.to.it/cms/risorse/intercomunale/prontuario_tariffe_extr_2019.pdf

const tariffe_gtt = {
	"Urbano Torino - Formula U": {
		tariff_id: "1", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Urbano Torino - Formula U",
		price: 310,
	},
	"Area Extraurbana - 1 Fascia": {
		tariff_id: "1", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 1 Fascia",
		price: 361,
	},
	"Area Extraurbana - 2 Fasce": {
		tariff_id: "2", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 2 Fasce",
		price: 462.5,
	},
	"Area Extraurbana - 3 Fasce": {
		tariff_id: "3", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 3 Fasce",
		price: 522.5,
	},
	"Area Extraurbana - 4 Fasce": {
		tariff_id: "4", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 4 Fasce",
		price: 587,
	},
	"Area Extraurbana - 5 Fasce": {
		tariff_id: "5", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 5 Fasce",
		price: 639,
	},
	"Area Extraurbana - 6 Fasce": {
		tariff_id: "6", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 6 Fasce",
		price: 678,
	},
	"Area Extraurbana - 7 Fasce": {
		tariff_id: "7", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 7 Fasce",
		price: 763,
	},
	"Area Extraurbana - 8 Fasce": {
		tariff_id: "8", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 8 Fasce",
		price: 763,
	},
	"Area Extraurbana - U-IVREA": {
		tariff_id: "8", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - U-IVREA",
		price: 289,
	},
	"Area Integrata - Formula 1": {
		tariff_id: "Formula 1",
		description: "Area Integrata - Formula 1",
		price: 394.5,
	},
	"Area Integrata - Formula 2": {
		tariff_id: "Formula 2",
		description: "Area Integrata - Formula 2",
		price: 442,
	},
	"Area Integrata - Formula 3": {
		tariff_id: "Formula 3",
		description: "Area Integrata - Formula 3",
		price: 627,
	},
	"Area Integrata - Formula 4": {
		tariff_id: "Formula 4",
		description: "Area Integrata - Formula 4",
		price: 765,
	},
	"Area Integrata - Formula 5": {
		tariff_id: "Formula 5",
		description: "Area Integrata - Formula 5",
		price: 879,
	},
	"Area Integrata - Formula 6": {
		tariff_id: "Formula 6",
		description: "Area Integrata - Formula 6",
		price: 964.5,
	},
	"Area Integrata - Formula 7": {
		tariff_id: "Formula 7",
		description: "Area Integrata - Formula 7",
		price: 1026,
	},
	"Area Integrata - Formula intera area": {
		tariff_id: "Formula i",
		description: "Area Integrata - Formula intera area",
		price: 1073.5,
	},
	"Area Integrata - Formula area PIN": {
		tariff_id: "Formula PIN",
		description: "Area Integrata - Formula area PIN",
		price: 442,
	},
	"Area Integrata + Fasce GTT - Formula 2+1": {
		tariff_id: "Formula-GTT 2+1",
		description: "Area Integrata + Fasce GTT - Formula 2+1",
		price: 532.5,
	},
	"Area Integrata + Fasce GTT - Formula 2+2": {
		tariff_id: "Formula-GTT 2+2",
		description: "Area Integrata + Fasce GTT - Formula 2+2",
		price: 618,
	},
	"Area Integrata + Fasce GTT - Formula 2+3": {
		tariff_id: "Formula-GTT 2+3",
		description: "Area Integrata + Fasce GTT - Formula 2+3",
		price: 703.5,
	},
	"Area Integrata + Fasce GTT - Formula 2+4": {
		tariff_id: "Formula-GTT 2+4",
		description: "Area Integrata + Fasce GTT - Formula 2+4",
		price: 779.5,
	},
	"Area Integrata + Fasce GTT - Formula 3+1": {
		tariff_id: "Formula-GTT 3+1",
		description: "Area Integrata + Fasce GTT - Formula 3+1",
		price: 717.5,
	},
	"Area Integrata + Fasce GTT - Formula 3+2": {
		tariff_id: "Formula-GTT 3+2",
		description: "Area Integrata + Fasce GTT - Formula 3+2",
		price: 803,
	},
	"Area Integrata + Fasce GTT - Formula 3+3": {
		tariff_id: "Formula-GTT 3+3",
		description: "Area Integrata + Fasce GTT - Formula 3+3",
		price: 888.5,
	},
	"Area Integrata + Fasce GTT - Formula 3+4": {
		tariff_id: "Formula-GTT 3+4",
		description: "Area Integrata + Fasce GTT - Formula 3+4",
		price: 964.5,
	},
	"Area Integrata + Tratte Trenitalia - Formula 2+1": {
		tariff_id: "Formula-Trenitalia 2+1",
		description: "Area Integrata + Tratte Trenitalia - Formula 2+1",
		price: 523,
	},
	"Area Integrata + Tratte Trenitalia - Formula 2+2": {
		tariff_id: "Formula-Trenitalia 2+2",
		description: "Area Integrata + Tratte Trenitalia - Formula 2+2",
		price: 599,
	},
	"Area Integrata + Tratte Trenitalia - Formula 2+3": {
		tariff_id: "Formula-Trenitalia 2+3",
		description: "Area Integrata + Tratte Trenitalia - Formula 2+3",
		price: 679.5,
	},
	"Area Integrata + Tratte Trenitalia - Formula 2+4": {
		tariff_id: "Formula-Trenitalia 2+4",
		description: "Area Integrata + Tratte Trenitalia - Formula 2+4",
		price: 746,
	},
	"Area Integrata + Tratte Trenitalia - Formula 2+5": {
		tariff_id: "Formula-Trenitalia 2+5",
		description: "Area Integrata + Tratte Trenitalia - Formula 2+5",
		price: 812.5,
	},
	"Area Integrata + Tratte Trenitalia - Formula 2+6": {
		tariff_id: "Formula-Trenitalia 2+6",
		description: "Area Integrata + Tratte Trenitalia - Formula 2+6",
		price: 874.5,
	},
	"Area Integrata + Tratte Trenitalia - Formula 3+1": {
		tariff_id: "Formula-Trenitalia 3+1",
		description: "Area Integrata + Tratte Trenitalia- Formula 3+1",
		price: 708,
	},
	"Area Integrata + Tratte Trenitalia - Formula 3+2": {
		tariff_id: "Formula-Trenitalia 3+2",
		description: "Area Integrata + Tratte Trenitalia - Formula 3+2",
		price: 784,
	},
	"Area Integrata + Tratte Trenitalia - Formula 3+3": {
		tariff_id: "Formula-Trenitalia 3+3",
		description: "Area Integrata + Tratte Trenitalia - Formula 3+3",
		price: 864.5,
	},
	"Area Integrata + Tratte Trenitalia - Formula 3+4": {
		tariff_id: "Formula-Trenitalia 3+4",
		description: "Area Integrata + Tratte Trenitalia - Formula 3+4",
		price: 931,
	},
	"Area Integrata + Tratte Trenitalia - Formula 3+5": {
		tariff_id: "Formula-Trenitalia 3+5",
		description: "Area Integrata + Tratte Trenitalia - Formula 3+5",
		price: 997.5,
	},
	"Area Integrata + Tratte Trenitalia - Formula 3+6": {
		tariff_id: "Formula-Trenitalia 3+6",
		description: "Area Integrata + Tratte Trenitalia - Formula 3+6",
		price: 1059.5,
	},
	"Area Integrata + Tratte EXTRA-TO - Formula 2+1": {
		tariff_id: "Formula-EXTRA-TO 2+1",
		description: "Area Integrata + Tratte EXTRA-TOa - Formula 2+1",
		price: 523,
	},
	"Area Integrata + Tratte EXTRA-TO - Formula 2+2": {
		tariff_id: "Formula-EXTRA-TO 2+2",
		description: "Area Integrata + Tratte EXTRA-TO - Formula 2+2",
		price: 599,
	},
	"Area Integrata + Tratte EXTRA-TO - Formula 3+1": {
		tariff_id: "Formula-EXTRA-TO 3+1",
		description: "Area Integrata + Tratte EXTRA-TO - Formula 3+1",
		price: 708,
	},
	"Area Integrata + Tratte EXTRA-TO - Formula 3+2": {
		tariff_id: "Formula-EXTRA-TO 3+2",
		description: "Area Integrata + Tratte EXTRA-TO - Formula 3+2",
		price: 784,
	},
};

export default tariffe_gtt;
