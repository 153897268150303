import DashboardLayout from "~theme/DashboardLayout.vue";

const routes = [
    {
		path: "/",
		component: DashboardLayout,
		redirect: "/dashboard",
		children: [
			{
				path: "/md-generate/:company_id/:office_id/:survey_id/:ignore_office",
				name: "Generatore di PSCL",

				meta: {
					authorize: ["admin", "moma", "moma_area"],
				},
				// lazy-loaded
				component: pageComponent("index"),
			},
			{
				path: "/md-editor/:company_id/:office_id/:survey_id/:ignore_office",
				name: "Editor di PSCL",
		
				meta: {
					authorize: ["admin", "moma", "moma_area"],
				},
				// lazy-loaded
				component: pageComponent("md-editor"),
			},
		]
	},

];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 * **/
function pageComponent(name) {	
	var res;	
		try {
			res = require("@/extra/MdGenerate/pages/" + name + ".vue").default;
		} catch (e) {
			console.log("Errore route");
			res = require("./pages/" + name + ".vue").default;
		}	
	return res;
}

export default routes;