import httpClient from "@/services/httpClient";

var mime = require("mime-types");

class MobilityLabelService {
	async edit(officeId, label_survey) {
		return httpClient({
			method: "post",
			url: `/mobility-label/label/edit/${officeId}.json`,
			data: {
				label_survey,
			},
		});
	}

    async view(office_id) {
		return httpClient.get(`/mobility-label/label/view/${office_id}.json`);
	}
}

export default new MobilityLabelService();
