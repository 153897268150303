<template>
    <div>
        <b-breadcrumb class="col-md-10">
            <b-breadcrumb-item href="/">
                <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                Home
            </b-breadcrumb-item>
            <b-breadcrumb-item href="/pendolare">Scelta Operatore</b-breadcrumb-item>
            <b-breadcrumb-item active> Riepilogo Richieste </b-breadcrumb-item>
        </b-breadcrumb>

        <h2>Le tue richieste</h2>
        <b-table :items="richieste" :fields="fields">
            <template #cell(richiesta)="data"> {{ data.item.created }} </template>
            <template #cell(stato)="data"> {{ stati_abbonamenti[data.item.status_id] }} </template>
            <template #cell(tipo_abbonamento)="data">
                <b-badge variant="primary">{{ getOperatore(data.item.testcommuter) }}</b-badge>
                {{ getAbbonamento(data.item.testcommuter) }}
            </template>
            <template #cell(valido_dal)="data"> {{ getValidita(data.item.testcommuter) }} </template>
        </b-table>
    </div>
</template>

<script>
import Util from "@/mixing/util";
import PendolareService from "../pendolare.service";

export default {
	name: "TestCommutersRiepilogo",
	mixins: [Util],
	data() {
		return {
			richieste: [],
			fields: ["Richiesta", "Tipo_abbonamento", "Valido_dal", "Stato"],
			stati_abbonamenti: [],
		};
	},
	computed: {},
	methods: {
		getValidita(x) {
			return x.mese_validita;
		},
		getAbbonamento(x) {
			return x.abbonamento_selezionato;
		},
		getOperatore(x) {
			return x.operatore;
		},
	},
	async created() {
		let res = await PendolareService.getRiepilogoAbbonamenti();
		res = res.data;
		if (res !== undefined) {
			this.richieste = res.testcommuters;
			this.stati_abbonamenti = res.statusLegend;
		}
	},
};
</script>

<style></style>
