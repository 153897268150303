<template>
    <div>
        <b-row class="mb-3">
            <b-col>
                <b-button size="sm" variant="outline-primary" @click.prevent="showModalIndicatori(null)">
                    <span class="ti-plus"></span>
                    Aggiungi punto di monitoraggio
                </b-button>
                &nbsp;
                <b-button @click="exportXls()" variant="primary" size="sm">
                    <span class="ti-file"></span>
                    Scarica XLS
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table
                    small
                    selectable
                    striped
                    :items="getMonitoringRows"
                    :fields="labels"
                    select-mode="single"
                    @row-clicked="onRowClicked"
                    :busy="busy"
                    :id="`tab-${measureId}`">
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>

                    <template #cell(azioni)="data">
                        <b-icon @click.prevent="showModalIndicatori(data.index)" icon="pencil"></b-icon>&nbsp;
                        <b-icon icon="trash" @click="deleteMonitoring(data.index)"></b-icon>&nbsp;
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Util from "@/mixing/util";
import PascalService from "../../pascal.service";
import XLSX from "xlsx";

export default {
	name: "monitoring",
	mixins: [Util],
	props: {
		measureId: Number,
		measure: Object,
		monitoring: Object,
		busy: Boolean,
	},
	data() {
		return {
			measure_id: null,
			labels: null,
		};
	},
	computed: {
		// getMonitoringColumns() {
		//   let res = [
		//     { key: "name", label: "nome punto di monitoraggio" },
		//     { key: "dt", label: "Data" },
		//   ];
		//   if (this.measure !== undefined) {
		//     res = res.concat(this.measure.labels);
		//   }

		//   return res.concat({ key: "azioni", label: "Azioni" });
		// },
		getMonitoringRows() {
			if (this.monitoring == undefined) {
				return [];
			}
			let s = this.monitoring;
			return s.series;
		},
	},
	async created() {
		try {
			this.labels = null;
			let lbls = await PascalService.getPascalMeasureLabels(this.measureId);
			let res = [
				{ key: "name", label: "nome punto di monitoraggio" },
				{ key: "dt", label: "Data" },
			];
			if (this.measure !== undefined) {
				res = res.concat(lbls.data.labels);
				res = res.concat({ key: "azioni", label: "Azioni" });
			}
			this.labels = res;
		} catch (error) {
			console.log(error);
		}
	},
	methods: {
		showModalIndicatori(row_index) {
			this.$forceUpdate();
			this.$emit("modal-open", { measure_id: this.measureId, row_index: row_index });
		},

		async deleteMonitoring(row_index) {
			//console.log("deletemonitoring, rowindex, measure_id", row_index, this.measureId);
			let monitoring_id = this.monitoring.series[row_index].id;
			if (monitoring_id != null) {
				PascalService.deleteMonitoring(monitoring_id);
			}
			this.monitoring.series.splice(row_index, 1);
			this.$root.$emit("bv::refresh::table", "tab-" + this.measureId);
		},
		onRowClicked(serie, idx) {
			//console.log("onRowclicked", serie, idx);
			this.$emit("row-clicked", { measure_id: this.measureId, row_index: idx });
		},
		exportXls() {
			let x = [];
			x.push([]); //La prima riga contiene i titoli
			this.getMonitoringColumns.forEach((col) => {
				if (col.label !== "Azioni") {
					x[0].push(col.label);
				}
			});

			//Le righe successive prendono i valori nell'ordine delle colonne
			let i = 1;
			this.getMonitoringRows.forEach((serie) => {
				x.push([]);
				this.getMonitoringColumns.forEach((col) => {
					if (col.label !== "Azioni") {
						if (serie[col.key] !== undefined) {
							x[i].push(serie[col.key]);
						} else {
							x[i].push(col.key);
						}
					}
				});
				i++;
			});

			//Genero il file excel
			let wb = XLSX.utils.book_new();
			wb.Props = {
				Title: "Esportazione Excel",
				Subject: "Esportazione Pascal",
				Author: "Pascal",
				CreatedDate: new Date(),
			};
			wb.SheetNames.push("Primo Foglio");
			let ws = XLSX.utils.aoa_to_sheet(x);
			wb.Sheets["Primo Foglio"] = ws;
			XLSX.writeFile(wb, "out.xlsx");
		},
	},
};
</script>

<style></style>
