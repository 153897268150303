<template>
    <div>
        <h2>Hai già un account trenord aziendale?</h2>
        <b-form-group required>
            <b-form-radio v-model="form.trenord.account_exists" name="trenord.account_exists" :value="1">Sì</b-form-radio>
            <!--:value in questo modo il programma capisce lo "0" come valore e non parola-->
            <b-form-radio v-model="form.trenord.account_exists" name="trenord.account_exists" :value="0">No</b-form-radio>
        </b-form-group>

        <b-alert :show="form.trenord.account_valid" variant="success">
            Le credenziali inserite sono valide, ed hai fornito l'autorizzazione ad operare su Trenord per tuo conto. 
            Ora puoi procedere con la richiesta di abbonamento.
        </b-alert>


        <!-- Se hai l'account mostro il form per fare il login -->        
            <form v-if="form.trenord.account_exists" ref="trenord" :disabled="disabled_fields">
                <label for="username"><b>*</b>Inserisci lo username di Trenord</label>
                <b-input v-model="form.trenord.credenziali.username" placeholder="Username Trenord" name="username" class="mb-2" :disabled="disabled_fields" required>
                </b-input>
                <label for="password"><b>*</b>Inserisci la password di Trenord</label>
                <b-input v-model="form.trenord.credenziali.password" placeholder="Password Trenord" name="password" class="mb-2" :disabled="disabled_fields"
                    type="password" required>
                </b-input>

                <b-form-checkbox v-model="form.trenord.privacy" required :state="form.trenord.privacy" :disabled="disabled_fields">
                    Autorizzo MobilitySquare ad operare sul portale Trenord a mio nome 
                    per le sole richieste provenienti da questo Sportello Abbonamenti.
                </b-form-checkbox>

                <b-button @click="trenordLogin()" class="float-right mt-3" variant="success" v-if="!disabled_fields">Login Trenord</b-button>
            </form>
        
    </div>
</template>


<script>
import AbbonamentiService from "../abbonamenti.service";

export default {
	data() {
		return {
			form: {
				trenord: {                    
					credenziali: {
						username: null,
						password: null,                    
					},
					privacy: false,
					account_valid: false,
				},                                  
			}
		};
	},
	props: ["force"], //Forza il login a Trenord
	methods: {
		trenordLogin() {            
			this.$refs.trenord.reportValidity();
			//Validates all the form fields           
			if (!this.form.trenord.privacy) {
				alert("Per poter proseguire è necessario concedere il consenso all'operazione da parte di MobilitySquare.");
				this.form.trenord.privacy = false;
				return;
			} else  {
				this.form.trenord.privacy = true;
			}
			if (this.$refs.trenord.checkValidity()) {
				//If the form is valid, try to login to https://www.trenordimpresa.it/web/mobility-Kuehne-Nagel
				AbbonamentiService.trenordLogin(this.form.trenord.credenziali.username, this.form.trenord.credenziali.password)
					.then((response) => {
						//console.log(response.data);
						//If the login is successful, show a success message
						if (response.data.success) {   
							this.form.trenord.account_valid = true;                            
						} else {
							this.$bvModal.msgBoxOk("Le credenziali inserite non sono riconosciute da Trenord, vuoi riprovare?");                            
						}
					})
					.catch((error) => {
						//If there is an error, show an error message
						//console.log(error.message);
					});
			}
		}
	},
	async mounted() {
		//Controllo se l'utente ha già un account Trenord
		let r = await AbbonamentiService.trenordAccountExists();        
		this.form.trenord.account_valid = r.data.success;
		if (r.data.success) {
			this.form.trenord.account_exists = 1;
			this.form.trenord.privacy = true;            
		}
	},
    computed: {
        //If the user has already an account, the form is disabled
        disabled_fields() {
            return this.form.trenord.account_valid && !this.force;
        }
    }

};
</script>